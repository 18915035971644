import addhdd from "./Add/addhdd"
import addmonitor from "./Add/addmonitor"
import addcable from "./Add/addcable.js"
export default function add(arr,product,callback) {
 if(product==="HDD"){
        addhdd(arr,function(val){
          callback(val)
        })
      }else if(product==="Monitor"){
        addmonitor(arr,function(val){
          callback(val)
        })
      }else if(product==="Cable"){
        addcable(arr,function(val){
          callback(val)
        })
      }
}
