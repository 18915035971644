export default function addcable(arr,callback){
    var row = document.createElement('tr')
    var td1 = document.createElement('td')
    var td2 = document.createElement('td')
    var td3 = document.createElement('td')
    var td4 = document.createElement('td')
    var td5 = document.createElement('td')
    var td6 = document.createElement('td')
    td1.innerHTML = document.getElementById('Model_Name6').value
    td2.innerHTML = document.getElementById('Price6').innerHTML
    td3.innerHTML = document.getElementById('Company5').innerHTML
    td4.innerHTML = document.getElementById('Cable_Lenght').innerHTML
    td5.innerHTML = document.getElementById('Quantity6').value
    td6.innerHTML = document.getElementById('Amount6').innerHTML
    row.append(td1,td2,td3,td4,td5,td6)
    arr.push({Model_Name:td1.innerHTML,Price:td2.innerHTML,Company:td3.innerHTML,Cable_Lenght:td4.innerHTML,Quantity:td5.innerHTML,Amount:td6.innerHTML})
    document.querySelector('.cable-tbody').appendChild(row)
    callback(arr)
}