import getbyseries from 'Backend/Firestore/getbyseries'
import errormessage from 'components/Main/error'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import addproduct from 'Backend/Firestore/upload'
import upload from 'Backend/Storage/upload'
import validateproduct from 'Backend/Firestore/validate'
export default function NVR() {
  function Back() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure that you want to go back?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, go back!'
    }).then((result) => {
      if (result.isConfirmed) {
        history.back()
      }
    })
  }
    const css = `
  .form-control{
        width: 99%;
    }
    body{
        overflow-x: hidden;
    }
        *::-webkit-scrollbar { 
    display: none;
}
    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`
var [cam,setcam] = useState(false)
var [Company,setCompany] = useState("HIKVISION")
var selectedcam = [] 
function filter(){
  selectedcam = []
  setCompany(document.getElementById("Company").value)
}
useEffect(() => {
  getbyseries('IP').then((snapshot) => {
    var set = []
    snapshot.docs.forEach((doc) => {
      var data = doc.data()
      data.id = doc.id
      set.push(data)
    })
    setcam(set)
  })
},[])
function addcamid(e,id,name){
  if(e.target.checked){
    selectedcam.push({id,name})
  }else{
    var index = selectedcam.indexOf({id,name});
    if (index > -1) {
      selectedcam.splice(index, 1);
    }
  }
}
function Add() {
  if(selectedcam.length === 0){
    errormessage("Please Select Camera")
  }
  else if(document.getElementById('Model_Name').value === ""){
    errormessage("Please Enter Model Name")
  }else if(document.getElementById('Model_Price').value === ""){
    errormessage("Please Enter Model Price")
  }else if(document.getElementById('PDF').value === ""){
    errormessage("Please Upload PDF")
  }else if(document.getElementById('NVR_Picture').value === ""){
    errormessage("Please Upload picture")
  }else{
    validateproduct('NVR',document.getElementById('Model_Name').value,()=>{
      var pdf = document.getElementById('PDF').files[0]
    var image = document.getElementById('NVR_Picture').files[0]
    upload('NVR',document.getElementById('Model_Name').value,pdf,image,(pdfurl,imageurl)=>{
      addproduct(document.getElementById('Available').value,'NVR',{
        Model_Name:document.getElementById('Model_Name').value,
        Model_Price:document.getElementById('Model_Price').value,
        Company:document.getElementById('Company').value,
        Supported_Cameras:selectedcam,
        Channel:document.getElementById('Channel').value,
        Audio_Channel: document.getElementById('Audio_Channel').value,
        PDF: pdfurl,
        NVR_Picture: imageurl
      })
      selectedcam = []
    })
    })
  }
}
function addrow(cam2) {
    return(
      <div key={cam2.Model_Name}>
        <div key={cam2.Model_Name} className='form-check' style={{display:'inline'}}>
        <input className='form-check-input' type='checkbox' id={cam2.Model_Name} data-name={cam2.Model_Name} value={cam2.Model_Name} onClick={(e)=>addcamid(e,cam2.id,cam2.Model_Name)}/>
        <label className='form-check-label' htmlFor={cam2.Model_Name}>
          {cam2.Model_Name}(<b>{cam2.Camera_Resolution}</b>) {cam2.Audio ? <b>Audio</b> : null}</label>
        </div>
        </div>
        )
}
  return (
    <>
      <style>{css}</style>
      <div className='row' style={{width: '90%',marginLeft: '5%'}}>
        <div className='col-sm-6 col-xs-12'>
            <label htmlFor='Model Name' className='form-label' style={{fontWeight:'bold'}}>NVR</label>
            <input type='text' className='form-control' id='Model_Name' placeholder='Enter Model Name' />
            <label htmlFor='Model Price' className='form-label' style={{fontWeight:'bold'}}>Model Price</label>
            <input type='text' className='form-control' id='Model_Price' placeholder='Enter Model Price' />
            <label htmlFor='Company' className='form-label' style={{fontWeight:'bold'}}>Company</label>
            <select data-name='Company' id='Company' className='form-select' style={{background: 'none'}} onChange={()=>filter()}>
                <option defaultValue='HIKVISION' defaultChecked>HIKVISION</option>
                <option defaultValue='CPPLUS'>CPPLUS</option>
                <option defaultValue='PRAMA'>PRAMA</option>
            </select>
            <label htmlFor='Channel' className='form-label' style={{fontWeight:'bold'}}>Display Channel</label>
            <select data-name='Channel' id='Channel' className='form-select' style={{background: 'none'}}>
                <option defaultValue='4'>4</option>
                <option defaultValue='8'>8</option>
                <option defaultValue='16'>16</option>
                <option defaultValue='32'>32</option>
                <option defaultValue='36'>36</option>
                <option defaultValue='64'>64</option>
            </select>
            <label htmlFor='Audio Channel' className='form-label' style={{fontWeight:'bold'}}>Audio Channel</label>
            <select data-name='Audio' id='Audio_Channel' className='form-select' style={{background: 'none'}}>
                <option defaultValue='1'>1</option>
                <option defaultValue='4'>4</option>
                <option defaultValue='8'>8</option>
                <option defaultValue='16'>16</option>
                <option defaultValue="32">32</option>
            </select>
            <label htmlFor="PDF" className="form-label" style={{fontWeight: "bold"}}>PDF</label>
                <input type="file" id="PDF" data-name="PDF" accept="application/pdf" className="form-control"/>
                <label htmlFor="NVR_Picture" className="form-label" style={{fontWeight: "bold"}}>NVR Picture</label>
                <input type="file" id="NVR_Picture" data-name="NVR_Picture" accept="image/*" className="form-control"/>
                <label htmlFor="Available" className="form-label" style={{fontWeight: "bold"}}>Available</label>
                    <select id="Available" className="form-control">
                        <option defaultValue="Yes">Yes</option>
                        <option defaultValue="No">No</option>
                    </select>
          </div>
          <div className='col-sm-6 col-xs-12' style={{paddingLeft:'50px',overflow: 'scroll',height: '500px'}}>
            <label htmlFor='Supported Camera' className='form-label' style={{fontWeight:'bold',textDecoration: 'underline'}}>Supported Camera</label>
            <div data-name='Supported_Camera' id='Supported_Camera'>
            {cam?cam.filter(cam => cam.Company === Company).map((cam2) => addrow(cam2)):"Loading..."}
          </div>
          </div>
      </div>
      <br/>
      <div className='row' style={{width: '90%',marginLeft: '5%'}}>
        <div className='col-sm-12 col-xs-12'style={{textAlign: 'center',display:'flex',justifyContent:' center',alignItems:'center'}}>
          <button className='btn btn-primary' style={{float: 'right',marginRight:'10px'}} onClick={()=>Add()}>Add NVR</button>
          <button className='btn btn-danger' style={{float: 'left'}} onClick={()=>Back()
          }>Back to Products</button>
        </div>
      </div>
    </>
  )
}
