import React from 'react'
import { Link } from 'react-router-dom'

export default function Structure() {
  return (
    <div>
      <div className="row">
      <div className="col-xs-12 col-sm-3">
                            <div className="card bg-gradient bg-info mt-12">
                                <div className=" card-header fw-bold"> CCTV Quotation </div>
                                <div className="card-body">
                                    <div className="card-text">
                                      <Link to="./Quotations/Add_CCTV_Quotation" className="btn btn-secondary mr-2">Add</Link>
                                    
                                    <Link to="./Quotations/View_CCTV_Quotation" className="btn btn-success">View</Link>
                                    </div>
                                </div>
                            </div>
                            <br/>
                        </div>
      </div>
    </div>
  )
}
