import React, { useState } from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import { FaUser, FaUsers, FaHome, FaSignOutAlt ,FaBars} from 'react-icons/fa';
import 'react-pro-sidebar/dist/css/styles.css';
import Swal from 'sweetalert2';
import Structure from '../User/Structure';
import Employees from '../User/Employees';
import Profile from '../User/Profile';
import { doSignOut } from 'Backend/Authentication/functions';
import { useAuth } from 'Backend/Authentication/Context';

export default function User_Dashboard() {
    const [collapsed, setCollapsed] = useState(false);
    const { setCurrentUser } = useAuth();
    const [page, setPage] = useState(<Structure />);
    const handlePageSelection = (Component) => {
        setPage(<Component />);
    };

    const signOut = async () => {
        const result = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Signout!'
        });
        if (result.isConfirmed) {
            doSignOut().then(() => {
                window.location.href = "/";
                setCurrentUser(null);
            }).catch((error) => {
                console.error("Sign out error", error);
            });
        }
    };

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <ProSidebar collapsed={collapsed}>
            <SidebarHeader>
                    <div style={{ padding: '24px', textTransform: 'uppercase', fontWeight: 'bold', fontSize: 14, letterSpacing: '1px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {collapsed ? '' : 'User Dashboard'}
                    </div>
                    <br />
                    <FaBars onClick={()=>{setCollapsed(!collapsed);}} style={{ cursor: 'pointer', position: 'absolute', top: '24px', right: '28px', fontSize: '20px' }} />
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        <MenuItem icon={<FaHome />} onClick={() => handlePageSelection(Structure)}>
                            Dashboard
                        </MenuItem>
                        <MenuItem icon={<FaUsers />} onClick={() => handlePageSelection(Employees)}>
                            Employees
                        </MenuItem>
                        <MenuItem icon={<FaUser />} onClick={() => handlePageSelection(Profile)}>
                            Profile
                        </MenuItem>
                        <MenuItem icon={<FaSignOutAlt />} onClick={signOut}>
                            Logout
                        </MenuItem>
                    </Menu>
                </SidebarContent>
            </ProSidebar>
            <main style={{ flex: 1, padding: '10px' }}>
                {page}
            </main>
        </div>
    );
}
