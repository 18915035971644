import { firestore } from "Backend/config"
import errormessage from "components/Main/error"
import { collection, getDocs } from "firebase/firestore"
export default function validateproduct(type,Model_Name,func) {
    var count = 0
    getDocs(collection (firestore, `Products/${type}/${type}`)).then((snapshot)=>{
        snapshot.forEach((doc)=>{
            if(doc.data().Model_Name === Model_Name){
                count += 1
            }
        })
}).then(()=>{
    if(count === 0){
        func()
    }else{
        errormessage("Product Already Exists")
    }
})
}