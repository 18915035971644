import addproduct from 'Backend/Firestore/upload'
import validateproduct from 'Backend/Firestore/validate'
import upload from 'Backend/Storage/upload'
import errormessage from 'components/Main/error'
import React from 'react'
import Swal from 'sweetalert2'

export default function Monitor() {
    const css = `
    .form-control{
          width: 99%;
      }
      body{
          overflow-x: hidden;
      }
      input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    `
    function Add() {
        if(document.getElementById("Model_Name").value === "" ){
        errormessage("Please Enter Model Name")
        }else  if(document.getElementById("Model_Price").value === "" ){
          errormessage("Please Enter Model Price")
        }else if(document.getElementById("Monitor_Picture").value === "" ){
          errormessage("Please Upload Picture")
        }else{
          validateproduct('Monitor',document.getElementById("Model_Name").value,()=>{
            upload("Monitor",document.getElementById("Model_Name").value,document.getElementById("PDF").files[0],document.getElementById("Monitor_Picture").files[0],(url,url2)=>{
              addproduct(document.getElementById('Available').value,"Monitor",{
                Model_Name: document.getElementById("Model_Name").value,
                Model_Price: document.getElementById("Model_Price").value,
                Company: document.getElementById("Company").value,
                Monitor_Size: document.getElementById("Monitor_Size").value,
                Supported_Cable: document.getElementById("Supported_Cable").value,
                PDF:url,
                Monitor_Picture:url2
              })
            })
          })
        }
    }
    function Back() {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure that you want to go back?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go back!'
        }).then((result) => {
          if (result.isConfirmed) {
            history.back()
          }
        })
      }
  return (
    <>
    <style>{css}</style>
      <center><h1>Add Monitor</h1></center>
      <div className="row" style={{width:'80%',marginLeft: '10%'}}>
        <div className="col-sm-6">
        <label htmlFor="Model_Name" className="form-label" style={{fontWeight: "bold"}}>Model Name</label>
                <input type="text" id="Model_Name" data-name="Model_Name" required className="form-control"/>
                <label htmlFor="Model_Price" className="form-label" style={{fontWeight: "bold"}}>Model Price</label>
                <input type="number" id="Model_Price" data-name="Model_Price" required className="form-control"/>
                <label htmlFor="Company" className="form-label" style={{fontWeight: "bold"}}>Company</label>
                <select data-name="Company" id="Company" className="form-control">
                    <option defaultValue="HIKVISION">HIKVISION</option>
                    <option defaultValue="CPPLUS">CPPLUS</option>
                    <option defaultValue="PRAMA">PRAMA</option>
                </select>
                <label htmlFor="Monitor_Size" className="form-label" style={{fontWeight: "bold"}}>Monitor Size</label>
                <select data-name="Monitor_Size" id="Monitor_Size" className="form-control">
                    <option defaultValue="19">19</option>
                    <option defaultValue="22">22</option>
                    <option defaultValue="32">32</option>
                    <option defaultValue="43">43</option>
                    <option defaultValue="55">55</option>
                    <option defaultValue="75">75</option>
                </select>
                <br/>
                </div>
                <div className="col-6">
                <label htmlFor='Supported_Cable' className="form-label" style={{fontWeight: "bold"}}>Supported Cable</label>
                <select data-name="Supported_Cable" id="Supported_Cable" className="form-control">
                    <option defaultValue="HDMI">HDMI</option>
                    <option defaultValue="VGA">VGA</option>
                    <option defaultValue="HDMI & VGA">HDMI & VGA</option>
                </select>
                <label htmlFor="Available" className="form-label" style={{fontWeight: "bold"}}>Available</label>
                    <select id="Available" className="form-control">
                        <option defaultValue="Yes">Yes</option>
                        <option defaultValue="No">No</option>
                    </select>
                <label htmlFor="PDF" className="form-label" style={{fontWeight: "bold"}}>PDF</label>
                <input type="file" id="PDF" data-name="PDF" accept="application/pdf" className="form-control"/>
                <label htmlFor="Monitor_Picture" className="form-label" style={{fontWeight: "bold"}}>Monitor Picture</label>
                <input type="file" id="Monitor_Picture" data-name="Monitor_Picture" accept="image/*" className="form-control"/>
                </div>
                <div className='row'>
                <div className='col-sm-12' style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                <button className="btn btn-primary" type="button" style={{marginRight: '10px'}} onClick={()=>{Add()}}>Save</button>
                <button className="btn btn-danger" type="button" onClick={()=>{Back()}}>Back to Products</button>
                </div>
                </div>
        </div>
    </>
  )
}
