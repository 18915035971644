import React from 'react'
import errormessage from 'components/Main/error'
import addproduct from 'Backend/Firestore/upload'
import upload from 'Backend/Storage/upload'
import Swal from 'sweetalert2'
import validateproduct from 'Backend/Firestore/validate'
export default function POE() {
    const css = `
    .form-control{
          width: 99%;
      }
      body{
          overflow-x: hidden;
      }
      input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    `
    function Add() {
        if(document.getElementById("Model_Name").value === "" ){
        errormessage("Please Enter Model Name")
        }else  if(document.getElementById("Model_Price").value === "" ){
          errormessage("Please Enter Model Price")
        }else if(document.getElementById("POE_Picture").value === "" ){
          errormessage("Please Upload Picture")
        }else if(document.getElementById("PDF").value === ""){
          errormessage("Please Upload PDF")
        }else{
          validateproduct('POE',document.getElementById("Model_Name").value,()=>{
            upload("POE",document.getElementById("Model_Name").value,document.getElementById("PDF").files[0],document.getElementById("POE_Picture").files[0],(url,url2)=>{
              addproduct(document.getElementById('Available').value,"POE",{
                Model_Name: document.getElementById("Model_Name").value,
                Model_Price: document.getElementById("Model_Price").value,
                Company: document.getElementById("Company").value,
                POE_Size: document.getElementById("POE_Size").value,
                POE_Type: document.getElementById("POE_Type").value,
                Link: (document.getElementById("Link").value === "Yes" ? true : false),
                SFP: (document.getElementById("SFP").value === "Yes" ? true : false),
                PDF:url,
                POE_Picture:url2
              })
            })
          })
        }
    }
    function Back() {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure that you want to go back?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go back!'
        }).then((result) => {
          if (result.isConfirmed) {
            history.back()
          }
        })
      }
  return (
    <>
    <style>{css}</style>
      <center><h1>Add POE</h1></center>
      <div className="row" style={{width:'90%',marginLeft: '5%'}}>
        <div className="col-sm-6">
        <label htmlFor="Model_Name" className="form-label" style={{fontWeight: "bold"}}>Model Name</label>
                <input type="text" id="Model_Name" data-name="Model_Name" required className="form-control"/>
                <label htmlFor="Model_Price" className="form-label" style={{fontWeight: "bold"}}>Model Price</label>
                <input type="number" id="Model_Price" data-name="Model_Price" required className="form-control"/>
                <label htmlFor="Company" className="form-label" style={{fontWeight: "bold"}}>Company</label>
                <select data-name="Company" id="Company" className="form-control">
                    <option defaultValue="HIKVISION">HIKVISION</option>
                    <option defaultValue="CPPLUS">CPPLUS</option>
                    <option defaultValue="PRAMA">PRAMA</option>
                </select>
                <label htmlFor="POE_Size" className="form-label" style={{fontWeight: "bold"}}>POE Size</label>
                <select data-name="POE_Size" id="POE_Size" className="form-control">
                  <option defaultValue="4P">4P</option>
                  <option defaultValue="5P">5P</option>
                  <option defaultValue="8P">8P</option>
                  <option defaultValue="16P">16P</option>
                  <option defaultValue="24P">24P</option>
                </select>
                <label htmlFor="Available" className="form-label" style={{fontWeight: "bold"}}>Available</label>
                    <select id="Available" className="form-control">
                        <option defaultValue="Yes">Yes</option>
                        <option defaultValue="No">No</option>
                    </select>
                <label htmlFor="PDF" className="form-label" style={{fontWeight: "bold"}}>PDF</label>
                <input type="file" id="PDF" data-name="PDF" accept="application/pdf" className="form-control"/>
        </div>
        <div className="col-sm-6">
             <label htmlFor='POE_Type' style={{fontWeight: 'bold'}} className='form-label'>POE Type</label>
             <select data-name="POE_Type" id="POE_Type" className="form-control">
             <option defaultValue="Normal">Normal</option>
             <option defaultValue="Gigabit">Gigabit</option>
             </select>
             <label htmlFor='Link' className='form-label' style={{fontWeight: 'bold'}}>Link</label>
             <select data-name="Link" id="Link" className="form-control">
             <option defaultValue="Yes">Yes</option>
             <option defaultValue="No">No</option>
             </select>
             <label htmlFor='SFP' className='form-label' style={{fontWeight: 'bold'}}>SFP</label>
             <select data-name="SFP" id="SFP" className="form-control">
             <option defaultValue="Yes">Yes</option>
             <option defaultValue="No">No</option>
             </select>
             <label htmlFor="POE_Picture" className="form-label" style={{fontWeight: "bold"}}>POE Picture</label>
                <input type="file" id="POE_Picture" data-name="POE_Picture" accept="image/*" className="form-control"/>
        </div>
      </div>
      <br/>
      <div className='row'>
                <div className='col-sm-12' style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                <button className="btn btn-primary" type="button" style={{marginRight: '10px'}} onClick={()=>{Add()}}>Save</button>
                <button className="btn btn-danger" type="button" onClick={()=>{Back()}}>Back to Products</button>
                </div>
                </div>
    </>
  )
}
