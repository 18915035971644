import { firestore } from "Backend/config";
import errormessage from "components/Main/error";
import {addDoc, collection } from "firebase/firestore";
import Swal from 'sweetalert2'
export default function addproduct(value,type,object) {
    addDoc(collection(firestore, `Products/${type}/${value==="Yes"?type:"Hide"}`),object).then((snapshot)=>{
        Swal.fire({
            icon: 'success',
            title: 'Product Added Successfully',
            showConfirmButton: false,
            timer: 1500
        })
        document.querySelectorAll('input').forEach((input)=>{
            input.value = ""
        })
        if(document.querySelectorAll('form-check-input')){
            document.querySelectorAll(".form-check-input").forEach((element)=>{element.checked = false})
        }
    }).catch((err)=>{
        errormessage(err.message)
    })
}