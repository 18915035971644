import { useAuth } from 'Backend/Authentication/Context'
import React, {useEffect, useState } from 'react'
import AdminDashboard from './Dashboard_Type/Admin_Dashboard.js'
import errormessage from 'components/Main/error.js'
import User_Dashboard from './Dashboard_Type/User_Dashboard.js'
import Employee_Dashboard from './Dashboard_Type/Employee_Dashboard.js'
import Swal from 'sweetalert2'
import { doSignOut } from 'Backend/Authentication/functions.js'
export default function Dashboard() {
    const {currentUser} = useAuth();
    const [dashboard,setdashboard] = useState(undefined)
    const [verfied,setverfied] = useState(true)
    const [verfied2,setverfied2] = useState(true)
    useEffect(() => {
      if(!verfied){
        if(verfied2){
          Swal.fire({
            icon: 'question',
            title: 'Your Email is not Verified',
            text: 'Want to Verify Email ?',
          }).then((result)=>{
            if(result.isConfirmed){
              currentUser.sendEmailVerification().then((result)=>{
                Swal.fire({
                  icon: 'success',
                  title: 'Email Verification Sent',
                }).then((result)=>{
                  doSignOut().then(()=>{window.location.pathname = "/"})
                })
              })
            }
          })
        }
      }
    },[verfied])
    var [data2,setdata2] = useState(undefined)
    useEffect(() => {
      currentUser.getdata((data)=>{
        if(data){
          setdata2(data)
          if(data.Access==="User"){
            setdashboard(<User_Dashboard data={data}/>)
          }else if(data.Access==="Employee"){
            setdashboard(<Employee_Dashboard data={data}/>)
          }else{
            setdashboard(<AdminDashboard data={data}/>)
          }
        }
      })
    },[])
    function validate(){
          errormessage('You are not Logined Please Make a sure that you are logned in').then((result)=>{
            if(result.isConfirmed){
              window.location.pathname = "/"
            }
          })
      }
      console.log(currentUser?.user)
      document.title = "CCTV Quote - Dashboard"            
      if(currentUser?.user){
          if(currentUser?.user.emailVerified){
            if(data2){
              if(data2.Enabled===false){
                 Swal.fire({
                    icon: 'error',
                    title: 'Your Account is Disabled',
                    text: 'Contact Admin',
                    footer: 'CCTV Quote :- 81330 47257',
                  }).then((result)=>{
                    if(result.isConfirmed){
                      window.location.pathname = "/"
                    }
                  })

                  doSignOut().catch((error)=>{
                    errormessage(error.message)
                  })
              }
              const today = new Date()
              
              if(data2.Subscription.toDate()<today){
                doSignOut().catch((error)=>{
                  errormessage(error.message)
                })
                Swal.fire({
                   icon: 'error',
                   title: 'Your Subscription is Expired',
                   text: 'Contact Admin',
                   footer: 'CCTV Quote :- 81330 47257',
                 }).then((result)=>{
                   if(result.isConfirmed){
                     window.location.pathname = "/"
                   }
                 })
             }
            }            
            return(dashboard)
          }else{
            setTimeout(()=>{setverfied(false)},1)
          }
      }else{        
        validate()        
      }
}
