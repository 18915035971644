import React, { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { FaTachometerAlt, FaUsers, FaProductHunt, FaChartLine, FaTools, FaSignOutAlt ,FaBars} from 'react-icons/fa';
import 'react-pro-sidebar/dist/css/styles.css';
import Swal from 'sweetalert2';
import { doSignOut } from 'Backend/Authentication/functions';
import AdminMain from '../Admin/AdminMain';
import AdminUsers from '../Admin/AdminUsers';
import AdminProducts from '../Admin/AdminProducts';
import AdminStructure from '../Admin/AdminStructure';
import Manage from '../Admin/Manage';

function Admin_Dashboard() {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(<AdminMain />);

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };
    const signOut = async () => {
        const result = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure you want to sign out?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Signout!'
        });

        if (result.isConfirmed) {
            doSignOut().then(() => {
                window.location.pathname = "/";
            }).catch(err => {
                alert(err.message); 
            });
        }
    };

    const handleNavigation = (Component) => {
        setSelectedComponent(<Component />);
    };

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <ProSidebar collapsed={collapsed}>
                <SidebarHeader>
                    <div style={{ padding: '24px', textTransform: 'uppercase', fontWeight: 'bold', fontSize: 14, letterSpacing: '1px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {collapsed ? '' : 'Admin Dashboard'}
                    </div>
                    <br />
                    <FaBars onClick={toggleSidebar} style={{ cursor: 'pointer', position: 'absolute', top: '24px', right: '28px', fontSize: '20px' }} />
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        <MenuItem icon={<FaTachometerAlt />} onClick={() => handleNavigation(AdminMain)}>
                            Dashboard
                        </MenuItem>
                        <MenuItem icon={<FaUsers />} onClick={() => handleNavigation(AdminUsers)}>
                            User Management
                        </MenuItem>
                        <MenuItem icon={<FaChartLine />} onClick={() => handleNavigation(AdminStructure)}>
                            Analytics
                        </MenuItem>
                        <MenuItem icon={<FaProductHunt />} onClick={() => handleNavigation(AdminProducts)}>
                            Products
                        </MenuItem>
                        <MenuItem icon={<FaTools />} onClick={() => handleNavigation(Manage)}>
                            Manage
                        </MenuItem>
                        <MenuItem icon={<FaSignOutAlt />} onClick={signOut}>
                            Logout
                        </MenuItem>
                    </Menu>
                </SidebarContent>
            </ProSidebar>
            <main style={{ flex: 1, padding: '10px' ,maxWidth:'100%',overflow:'scroll'}}>
                {selectedComponent}
            </main>
        </div>
    );
}

export default Admin_Dashboard;