export default function addmonitor(arr,callback) {
    var tbody = document.querySelector(".monitor-tbody")
    var row = document.createElement("tr")
    var td1 = document.createElement("td")
    var td2 = document.createElement("td")
    var td3 = document.createElement("td")
    var td4 = document.createElement("td")
    var td5 = document.createElement("td")
    var td6 = document.createElement("td")
    var td7 = document.createElement("td")
    td1.innerHTML = document.getElementById("Model_Name5").value
    td2.innerHTML = document.getElementById("Price5").innerHTML
    td3.innerHTML = document.getElementById("Company4").innerHTML
    td4.innerHTML = document.getElementById("Screen_Size").innerHTML
    td5.innerHTML = document.getElementById("Supported_Cables").innerHTML
    td6.innerHTML = document.getElementById("Quantity5").value
    td7.innerHTML = document.getElementById("Amount5").innerHTML
    row.append(td1,td2,td3,td4,td5,td6,td7)
    arr = [{
        Model_Name:td1.innerHTML,
        Price:td2.innerHTML,
        Company:td3.innerHTML,
        Screen_Size:td4.innerHTML,
        Supported_Cable:td5.innerHTML,
        Quantity:td6.innerHTML,
        Amount:td7.innerHTML
    }]
    tbody.append(row)
    callback(arr)
}