import {  deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage'
import { firestore, storage } from 'Backend/config'
import Swal from 'sweetalert2';
import errormessage from 'components/Main/error';
import { doc, updateDoc } from 'firebase/firestore';
export default function editpdf(filetype,type,id,name,url){
    if(filetype === 'pdf'){
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure that you want to edit PDF?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        html : `<button type='button' class='btn btn-warning' onclick='window.open("${url}")'>View it</button><br/><label for='pdf' style='font-weight: bold;' class='form-label'>Upload PDF</label><br/><input type='file' class='form-control-file' style='text-align: center;' id='pdf' accept='application/pdf'>`,
    }).then((result) => {
      var path;
      var file;
        if(document.getElementById('pdf').files[0] !== undefined){
          file = document.getElementById('pdf').files[0]
            listAll(ref(storage, `Products/${type}/${name}/PDF`)).then((res) => {
              path = ref(storage,res.items[0].fullPath)
                deleteObject(path).then(() => {
                uploadBytes(path,file,{contentType:'application/pdf'}).then((snapshot) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'PDF updated successfully!',
                        timer: 3000,
                        showConfirmButton: false
                    }).then(() => {
                        getDownloadURL(snapshot.ref).then((url) => {
                            updateDoc(doc(firestore, `Products/${type}/${type}/${id}`), {
                              PDF: url
                            })
                        })
                    })
                  })
                })
            })
        }else{
            if(result.isConfirmed){
              errormessage("Please Upload PDF")
            }
        }
    })
    }else{
      console.log(url);
      
      if(filetype === 'image'){
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure that you want to edit Image?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          html : `<center><img src='${url}' width='300px' height='300px' class='img-thumbnail' /></center><br/><label for='pdf' style='font-weight: bold;' class='form-label'>Upload Image</label><br/><input type='file' class='form-control-file' style='text-align: center;' id='pdf' accept='image/*'>`,
      }).then((result) => {
        var path;
        var file;
          if(document.getElementById('pdf').files[0] !== undefined){
            file = document.getElementById('pdf').files[0]
              listAll(ref(storage, `Products/${type}/${name}/Image`)).then((res) => {
                path = ref(storage,res.items[0].fullPath)
                  deleteObject(path).then(() => {
                  uploadBytes(path,file,{contentType:'image/*'}).then((snapshot) => {
                      Swal.fire({
                          icon: 'success',
                          title: 'Success',
                          text: 'Image updated successfully!',
                          timer: 3000,
                          showConfirmButton: false
                      }).then(() => {
                          getDownloadURL(snapshot.ref).then((url) => {
                              updateDoc(doc(firestore, `Products/${type}/${type}/${id}`), {
                                Image: url
                              })
                          })
                      })
                    })
                  })
                })
            }else{
                if(result.isConfirmed){
                  errormessage("Please Upload Image")
                }
            }
        })
      }
    }
}
