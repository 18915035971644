export default function addhdd(arr,callback) {
    var tbody = document.querySelector(".hdd-tbody")
    var row = document.createElement("tr")
    var td1 = document.createElement("td")
    var td2 = document.createElement("td")
    var td3 = document.createElement("td")
    var td4 = document.createElement("td")
    var td5 = document.createElement("td")
    var td6 = document.createElement("td")
    td1.innerHTML = document.getElementById("Model_Name4").value
    td2.innerHTML = document.getElementById("Price4").innerHTML
    td3.innerHTML = document.getElementById("Company2").innerHTML
    td4.innerHTML = document.getElementById("Terabyte").innerHTML
    td5.innerHTML = document.getElementById("Quantity4").value
    td6.innerHTML = document.getElementById("Amount4").innerHTML
    row.append(td1,td2,td3,td4,td5,td6)
    arr = [{
        Model_Name: document.getElementById("Model_Name4").value,
        Price: document.getElementById("Price4").innerHTML,
        Company: document.getElementById("Company2").innerHTML,
        Terabyte: document.getElementById("Terabyte").innerHTML,
        Quantity: document.getElementById("Quantity4").value,
        Amount: document.getElementById("Amount4").innerHTML
    }]
    tbody.append(row)
    callback(arr)
}
