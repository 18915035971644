import React, { useState } from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import { FaUser, FaHome, FaSignOutAlt,FaBars } from 'react-icons/fa';
import { useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import Swal from 'sweetalert2';
import Structure from '../Employee/Structure';
import Profile from '../Employee/Profile';
import { doSignOut } from 'Backend/Authentication/functions';

export default function Employee_Dashboard() {
    const [collapsed, setCollapsed] = useState(false);
    const [page, setPage] = useState();

    useEffect(() => {
        // Set initial page based on the URL path
        const handlePageLoad = () => {
            const path = window.location.pathname;
            switch(path) {
                case "/dashboard/Profile":
                    setPage(<Profile />);
                    break;
                default:
                    setPage(<Structure />);
                    break;
            }
        };
        handlePageLoad();

        // Handle responsive sidebar
        const handleResize = () => setCollapsed(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        handleResize();  // Call it initially to set the correct state based on the current window width
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const signOut = async () => {
        const result = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Signout!'
        });

        if (result.isConfirmed) {
            doSignOut().then(() => {
                window.location.href = "/";
            }).catch((error) => {
                console.error("Sign out error", error);  // Consider handling this error more gracefully in your UI
            });
        }
    };

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <ProSidebar collapsed={collapsed}>
            <SidebarHeader>
                    <div style={{ padding: '24px', textTransform: 'uppercase', fontWeight: 'bold', fontSize: 14, letterSpacing: '1px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {collapsed ? '' : 'User Dashboard'}
                    </div>
                    <br />
                    <FaBars onClick={()=>{setCollapsed(!collapsed);}} style={{ cursor: 'pointer', position: 'absolute', top: '24px', right: '28px', fontSize: '20px' }} />
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        <MenuItem icon={<FaHome />} onClick={() => setPage(<Structure />)}>
                            Dashboard
                        </MenuItem>
                        <MenuItem icon={<FaUser />} onClick={() => setPage(<Profile />)}>
                            Profile
                        </MenuItem>
                        <MenuItem icon={<FaSignOutAlt />} onClick={signOut}>
                            Logout
                        </MenuItem>
                    </Menu>
                </SidebarContent>
            </ProSidebar>
            <main style={{ flex: 1, padding: '10px' }}>
                {page}
            </main>
        </div>
    );
}
