export default function addvr(arr,product,cb) {
    if(product==="DVR"){
        var row = document.createElement("tr")
        var td1 = document.createElement("td")
        var td2 = document.createElement("td")
        var tdchannel = document.createElement("td")
        var tdchannel32 = document.createElement("td")
        var td3 = document.createElement("td")
        var td4 = document.createElement("td")
        td1.innerHTML = document.getElementById("VR_Name").value
        td2.innerHTML = document.getElementById("Price2").innerHTML
        tdchannel.innerHTML = document.getElementById("Channel").innerHTML
        tdchannel32.innerHTML = document.getElementById("Audio_Channel").innerHTML
        td3.innerHTML = document.getElementById("Quantity2").value
        td4.innerHTML = ((td2.innerHTML)*(td3.innerHTML))
        arr = [{
            Model_Name:document.getElementById("VR_Name").value,
            Quantity:document.getElementById("Quantity2").value,
            Channel:document.getElementById("Channel").innerHTML,
            Audio_Channel:document.getElementById("Audio_Channel").innerHTML,
            Price:document.getElementById("Price2").innerHTML,
            Amount: td2.innerHTML
        }]
        row.append(td1,td2,tdchannel,tdchannel32,td3,td4)
        document.querySelector("."+product+"-tbody").append(row)
        document.getElementById("VR_Name").disabled = true
        document.getElementById("Quantity2").disabled = true
        document.getElementById("Price2").disabled = true
        document.getElementById("Channel").disabled = true
        cb(arr) 
    }else if(product==="NVR"){
        var row2 = document.createElement("tr")
        var td12 = document.createElement("td")
        var td22 = document.createElement("td")
        var tdchannel2 = document.createElement("td")
        var tdchannel3 = document.createElement("td")
        var td32 = document.createElement("td")
        var td42 = document.createElement("td")
        td12.innerHTML = document.getElementById("VR_Name").value
        td22.innerHTML = document.getElementById("Price2").innerHTML
        tdchannel2.innerHTML = document.getElementById("Channel").innerHTML
        tdchannel3.innerHTML = document.getElementById("Audio_Channel").innerHTML
        td32.innerHTML = document.getElementById("Quantity2").value
        td42.innerHTML = ((td22.innerHTML)*(td32.innerHTML))
        arr = [{
            Model_Name:document.getElementById("VR_Name").value,
            Quantity:document.getElementById("Quantity2").value,
            Channel:document.getElementById("Channel").innerHTML,
            Audio_Channel:document.getElementById("Audio_Channel").innerHTML,
            Price:document.getElementById("Price2").innerHTML,
            Amount: ((td22.innerHTML)*(td32.innerHTML))
        }]
        row2.append(td12,td22,tdchannel2,tdchannel3,td32,td42)
        document.querySelector("."+product+"-tbody").append(row)
        document.getElementById("VR_Name").disabled = true
        document.getElementById("Quantity2").disabled = true
        document.getElementById("Price2").disabled = true
        document.getElementById("Channel").disabled = true
        cb(arr)
    }
}