import React from 'react';
import { Link } from 'react-router-dom';
import { FaCamera, FaNetworkWired, FaHdd, FaDesktop, FaPlug, FaCogs, FaTools, FaVideo } from 'react-icons/fa'; // Import icons

export default function AdminProducts(props) {
    document.title = "CCTV Quote - Products";
    var css = `
      .btn-secondary, .btn-success, .btn-danger {
        margin-bottom: 10px;
      }
      .card-icon {
        font-size: 2rem;
        margin-bottom: 10px;
      }
        .btn-secondary {
            margin-bottom: 10px;
        }
        .btn-success, .btn-danger {
            margin-left: 10px;
            margin-bottom: 10px;
        }
        .card {
            border: none;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        .card:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(0,0,0,0.2);
        }
        .card-header {
            background-color: rgba(0,123,255,0.7);
            color: white;
        }
        .card-body {
            background-color: #f8f9fa;
        }
    `;

    const products = [
        { name: 'Camera', icon: <FaCamera />, links: ['./Products/Add_Camera', './Products/View-Camera', './Products/Edit-Camera'] },
        { name: 'NVR', icon: <FaVideo />, links: ['./Products/Add_NVR', './Products/View-NVR'] },
        { name: 'DVR', icon: <FaHdd />, links: ['./Products/Add_DVR', './Products/View-DVR'] },
        { name: 'Power Supply Unit', icon: <FaPlug />, links: ['./Products/Add_PSU_SMPS', './Products/View-PSU'] },
        { name: 'Cable', icon: <FaNetworkWired />, links: ['./Products/Add_Cable', './Products/View-Cable'] },
        { name: 'Hard Disk', icon: <FaHdd />, links: ['./Products/Add_Hard_Disk', './Products/View-HDD'] },
        { name: 'Monitor', icon: <FaDesktop />, links: ['./Products/Add_Monitor', './Products/View-Monitor'] },
        { name: 'POE Switch', icon: <FaPlug />, links: ['./Products/Add_POE_Switch', './Products/View-POE'] },
        { name: 'Accessories', icon: <FaTools />, links: ['./Products/Add_Accessories', './Products/View-Accessories'] }
    ];

    return (
        <>
            <style>{css}</style>
            <div className="container mt-5">
                <div className="row">
                    {products.map((product, index) => (
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                            <div className="card h-100 bg-gradient bg-info text-white shadow-sm">
                                <div className="card-header text-center fw-bold">
                                    {product.icon} {product.name}
                                </div>
                                <div className="card-body text-center">
                                    <div className="btn-group" role="group">
                                        {product.links.map((link, idx) => (
                                            <Link key={idx} to={link} className={'btn btn-' + (idx === 0 ? 'secondary' : idx === 1 ? 'success' : 'danger')}>
                                                {idx === 0 ? 'Add' : idx === 1 ? 'View' : 'Edit'}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
