import { firestore } from 'Backend/config'
import { deleteUser } from 'firebase/auth'
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

export default function AdminUsers() {
  document.title = "CCTV Quote - Users"
  const [users,setusers] = useState(undefined)
  useEffect(() => {
    getDocs(query(collection(firestore,"Users"),where("Access","==","User"))).then((data)=>{
      setusers(data.docs)
    })
  },[])
function addSubscription(){
  console.log("Add Subscription")
} 
  const setstatus = (uid,status) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    }).then((result) => {
      if (result.isConfirmed) {
        updateDoc(doc(firestore, `Users/${uid}`), {
          Enabled: status?false:true
        })
        .then(() => {
          document.querySelector(".change-status-btn").innerHTML = status?'Enable':'Disable'
          document.querySelector(".tbody").querySelectorAll("tr").forEach((element)=>{
              element.querySelector(".enabled-span").innerHTML = status?'<span class="text-danger">Disabled</span>':'<span class="text-success">Enabled</span>'
          })
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Status changed successfully!',
            timer: 3000,
            showConfirmButton: false
          })
        })
      }
    })
  }
  const viewmore = (data) => {
    Swal.fire({
      title: data.Business_Name,
      html: `<p>Email : ${data.Email}</p><br/><p>Primary Phone : ${data.Contact_No_1}</p><br/><p>Secondary Phone : ${data.Contact_No_2}</p><br/><p>Address : ${data.Address_1}<br/>${data.Address_2}</p>
      <br/><p>Access : ${data.Access}</p><br/><p>Created : ${data.Creation_Time.toDate().toDateString()}</p><br/><p>Last Login : ${data.Last_Login.toDate().toDateString()}</p>
    <br/><p>Password : <button class="btn btn-danger" onclick="if(this.textContent==='View Password'){this.innerHTML = '${data.Password}';this.disabled = true;setTimeout(()=>{this.innerHTML = 'View Password';this.disabled = false},3000)}else{this.innerHTML = 'View Password'}">View Password</button></p>`,
    })
  }
  return (
    <div className='pr-6'>
      <br />
      <center><h1 className='h3'>Users</h1></center>
      <br />
      <table>
        <thead>
          <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Access</th>
          <th>Status</th>
          <th>Enable/Disable</th>
          <th>Subscription</th>
          <th>View More</th>
          </tr>
        </thead>
        <tbody className='tbody'>
        {users && users.map((user)=>{
        return(<tr key={user.id}>
          <td>{user.data().Business_Name}</td>
        <td>{user.data().Email}</td>
        <td>{user.data().Access}</td>
        <td className='enabled-span'>{user.data().Enabled?<span className="text-success">Enabled</span>:<span className="text-danger">Disabled</span>}</td>
        <td><button className='btn btn-primary change-status-btn' onClick={() => {setstatus(user.id,user.data().Enabled,)}}>{user.data().Enabled?"Disable":"Enable"}</button></td>
        <td><button className='btn btn-primary' onClick={() => {addSubscription(user.id)}}>Subscription</button></td>
        <td><button className='btn btn-primary' onClick={() => {viewmore({...user.data(),id:user.id})}}>View More</button></td>
        </tr>)
      })}
        </tbody>
      </table>
    </div>
  )
}