import { firestore } from 'Backend/config'
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where, } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

export default function hIDE() {
    const [data,setdata] = useState([])
    useEffect(() => {
        function getdata() {
          var arr = []
          getDocs(collection(firestore,'Products')).then((res)=>{
            res.docs.forEach((docref)=>{
                getDocs(collection(firestore,'Products/'+docref.id+"/"+docref.id)).then((res)=>{
                  res.docs.forEach((doc)=>{
                    arr.push({id:doc.id,...doc.data(),Type:docref.id})
                    setdata(arr)
                  })
                })
            })
        })
        }
        getdata()
    },[])
    function HIDE(type,id,productName) {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to hide the product: ${productName}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, hide it!',
            cancelButtonText: 'No, keep it',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                const docRef = doc(firestore, 'Products/'+type+'/'+type+'/'+id);
                try
                {
                    getDoc(docRef).then((res)=>{
                        deleteDoc(docRef).then(()=>{
                            setDoc(doc(firestore, 'Products/'+type+'/Hide/'+id), {
                                ...res.data()
                            }).then(()=>{
                                Swal.fire(
                                    'Hidden!',
                                    `${productName} has been hidden.`,
                                    'success'
                                );
                                var index = data.findIndex(obj => obj.id===id);
                                const data2 = [...data];
                                  data2.splice(index, 1);
                                  setdata(data2);
                            })
                        })
                    })
                }catch(error){
                    errormessage(error)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    `${productName} is still accessible.`,
                    'error'
                );
            }
        });
};
  return (
    <div>
        <br />
      <div className="row">
        <div className="col-xs-12 col-sm-6 text-center">
            <h3>Hide Model</h3>
        </div>
        <div className="col-xs-12 col-sm-6">
            <button className='btn btn-primary' onClick={()=>{Swal.fire({title: 'Are you sure?',
                icon: 'warning',
                confirmButtonText: 'Yes, go back '}).then((result) => {if (result.isConfirmed) {history.back()}})}}>Go Back</button>

        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-12">
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Model Name</th>
                            <th>Model Type</th>
                            <th>Hide</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length!==0?data.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.Model_Name}</td>
                                    <td>{item.Type}</td>
                                    <td><button className='btn btn-primary' onClick={()=>{HIDE(item.Type,item.id,item.Model_Name)}}>Hide Model</button></td>
                                </tr>
                            )
                        }):null}
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  )
}