import { firestore } from 'Backend/config';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'

export default function AdminMain() {
    const [data,setdata] = useState(false)
    const [data2,setdata2] = useState(false)
    const [data3,setdata3] = useState(false)
const CountCard = ({ name, count }) => {
        return (
            <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    {name}
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                    {count}
                                </div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        };
useEffect(() => {
    const startOfMonth = new Date();
    startOfMonth.setDate(1);
    startOfMonth.setHours(5, 0, 0, 0);
    const endOfMonth = new Date(startOfMonth);
    endOfMonth.setMonth(endOfMonth.getMonth() + 1);
    const fetchData = async () => {
        var data = [];
        var data2 = [];
        var data3 = [];
        var today = new Date();
        const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        const querySnapshot = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","User"), where("Creation_Time", ">=", startOfMonth), where("Creation_Time", "<", endOfMonth)));
        const querySnapshot2 = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","Employee"), where("Creation_Time", ">=", startOfMonth), where("Creation_Time", "<", endOfMonth)));
        const querySnapshot3 = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","User"), where("Last_Login", ">=", startOfDay), where("Last_Login", "<", endOfDay)));
        const querySnapshot4 = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","Employee"), where("Last_Login", ">=", startOfDay), where("Last_Login", "<", endOfDay)));
        const querySnapshot5 = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","User")));
        const querySnapshot6 = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","Employee")));
        const querySnapshot7 = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","User"),where("Subscription",">=",new Date())));
        const querySnapshot8 = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","User"),where("Subscription","<=",new Date())));
        const querySnapshot9 = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","User"),where("Enabled","==",true)));
        const querySnapshot10 = await getCountFromServer(query(collection(firestore, "Users"),where("Access","==","User"),where("Enabled","==",false)));
        data.push({ name: "User Created this month", count: querySnapshot.data().count });
        data.push({ name: "Employee Created this month", count: querySnapshot2.data().count });
        data.push({ name: "User Logins in last 24 hours", count: querySnapshot3.data().count });
        data.push({ name: "Employee Logins in last 24 hours", count: querySnapshot4.data().count });
        data2.push({ name: "Total Users", count: querySnapshot5.data().count });
        data2.push({ name: "Total Employee", count: querySnapshot6.data().count });
        data2.push({ name: "Total Subscribed Users", count: querySnapshot7.data().count });
        data2.push({ name: "Total Unsubscribed Users", count: querySnapshot8.data().count });
        data3.push({ name: "Total Active Users", count: querySnapshot9.data().count });
        data3.push({ name: "Total Inactive Users", count: querySnapshot10.data().count });
        setdata(data)
        setdata2(data2)
        setdata3(data3)
    }
    fetchData()
},[])
  return (
    <div className='container mr-12'>
        <br />
        <br />
        <div className="row">
            {data?data.map((element, index) => (
                <CountCard key={index} name={element.name} count={element.count} />
            )):null}
        </div>
        <div className="row">
            {data2?data2.map((element, index) => (
                <CountCard key={index} name={element.name} count={element.count} />
            )):null}
        </div>
        <div className="row">
            {data3?data3.map((element, index) => (
                <CountCard key={index} name={element.name} count={element.count} />
            )):null}
        </div>
    </div>
  )
}
