import { auth } from "Backend/config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  sendEmailVerification,
} from "firebase/auth";
export const doCreateUserWithEmailAndPassword = async (email, password) => {
  return  createUserWithEmailAndPassword(auth, email, password);
};

export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};
export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};
export const doverifyEmail = (email) => {
  return sendEmailVerification(auth.currentUser)
}
export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};
