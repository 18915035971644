import { useAuth } from "Backend/Authentication/Context"
import { doCreateUserWithEmailAndPassword, doSignInWithEmailAndPassword } from "Backend/Authentication/functions"
import {  firestore, storage } from "Backend/config"
import errormessage from "components/Main/error"
import { doc, setDoc } from "firebase/firestore"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import Swal from "sweetalert2"
export default function Signup() {
    const {setUpRecaptha,verifyemail} = useAuth()
    function setotp(user2){
          uploadBytes(ref(storage, `Users/${user2.uid}/Image/${document.getElementById('image').files[0].name}`), document.getElementById('image').files[0]).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              doCreateUserWithEmailAndPassword(document.querySelector('input[type="email"]').value, document.getElementById('password').value)
        .then((cred) => {
          var user = cred.user
          setDoc(doc(firestore, `Users/${user.uid}`), {
            uid: user.uid,
            Moblie_UID: user2.uid,
            Business_Name: document.getElementById('businessname').value,
            Email: document.querySelector('input[type="email"]').value,
            Last_Login: new Date(),
            Access: "User",
            MaxEmploy: 2,
            Image: url,
            Description: "",
            GSTIN: document.getElementById('gstin').value,
            Creation_Time: new Date(),
            Address_1: document.getElementById('address1').value,
            Address_2: document.getElementById('address2').value,
            Contact_No_1: document.getElementById('contactno1').value,
            Contact_No_2: document.getElementById('contactno2').value,
            Password: document.getElementById('password').value,
            Subscription: new Date()
          }).then(() => {
            doSignInWithEmailAndPassword(document.querySelector('input[type="email"]').value, 
            document.getElementById('password').value).then((cred) => {
              var user = cred.user
              verifyemail(user).then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Registration Done. Email Verification Sent',
                  showConfirmButton: false,
                  timer: 1500
                })
              })
        })
            })
          })
        }).catch((err) => {
          errormessage(err)
        })
          })
    }
    async function handleSubmit() {
      var err = ""
      document.querySelectorAll('input').forEach((input)=>{
        if(input.value === ""){
          err = "Please fill all the fields"
        } 
      })
      if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(document.querySelector('input[type="email"]').value)){
        err = "Invalid Email"
      }
      if(!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(document.getElementById('password').value)){
        err = "Password must contain atleast one special character and number and minimum 6 characters and maximum 16 characters"
      }

      if(err !== ""){
        errormessage(err)
      }
      else{
        document.querySelectorAll('input').forEach((input)=>{
          input.disabled = true
        })
        document.querySelector('.handlesubmit').disabled = true
        setUpRecaptha(document.getElementById('contactno1').value,setotp)
      }
    }
    
    function handleSubmit2(e) {
      const password = document.querySelector('#password');
        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        e.textContent = type === 'password' ? 'Show' : 'Hide';
    };
    return (
        <div className="w-50 m-auto mt-5 mb-5" style={{borderRadius:"10px",padding:"10px",boxShadow:"0 4px 8px rgba(0,0,0,0.2)",backgroundColor:"white"}}>
            <h1 className="text-center" style={{fontWeight:"bold",fontSize:"26px",marginBottom:"20px"}}>Register</h1>
            <form>
                <label className="form-label" style={{fontWeight:"bold"}}>Business Name</label>
                <input
                    className="form-control"
                    type="text"
                    id="businessname"
                />
                 <label className="form-label" style={{fontWeight:"bold"}}>Address Line 1</label>
                <input
                    className="form-control"
                    type="text"
                    id="address1"
                />
                <label className="form-label" style={{fontWeight:"bold"}}>Address Line 2</label>
                <input
                    className="form-control"
                    type="text"
                    id="address2"
                />
                <label className="form-label" style={{fontWeight:"bold"}}>GSTIN</label>
                <input
                    className="form-control"
                    type="text"
                    id="gstin"
                />
                <label className="form-label" style={{fontWeight:"bold"}}>Contact No.</label>
                <input
                    className="form-control"
                    type="tel"
                    onInput={(e) => {if(e.target.value.length > 10){e.target.value = e.target.value.slice(0,10)}}}
                    id="contactno1"
                />
                <label className="form-label" style={{fontWeight:"bold"}}>Alternate Contact No.</label>
                <input
                    className="form-control"
                    type="tel"
                    onInput={(e) => {if(e.target.value.length > 10){e.target.value = e.target.value.slice(0,10)}}}
                    id="contactno2"
                />
                <label className="form-label" style={{fontWeight:"bold"}}>Email</label>
                <input
                    className="form-control"
                    type="email"
                />
                <label className="form-label" style={{fontWeight:"bold"}}>Password</label>
                <div className="input-group">
                            <input name="password" type="password" className="form-control w-80" id="password" placeholder="Password"/>
                                <button className="btn btn-outline-secondary" onClick={(e)=>{handleSubmit2(e)}} type="button" id="togglePassword">Show</button>
                            </div>
                            <label className="form-label" style={{fontWeight:"bold"}}>Image/Logo</label>
                <input
                    className="form-control"
                    type="file"
                    id="image"
                    accept="image/*"
                />
                <br />
                <button onClick={handleSubmit} className="btn btn-primary handlesubmit" type="button">
                    Register
                </button>
                <center><div id="recaptcha-container"></div></center>
            </form>
            <div id="recaptcha2"></div>
        </div>
    );
}