import { storage } from "Backend/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
export default function upload2(type,name,image,func) {
    const imageref = ref(storage, `Products/`+type+'/'+name+'/Image/'+name+'.'+(image.name.split('.').pop()))
    uploadBytes(imageref,image,{contentType:'application/image'}).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((imageurl) => {
                    func(imageurl)
            })
     })
}
