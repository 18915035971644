import React,{ useState } from 'react';
import { Link } from 'react-router-dom';

function BasicNavbar() {
  var [active,setactive] = useState(
    window.location.href.includes("signup") ? "signup" : 
    window.location.href.includes("dashboard") ? "dashboard" : 
    "home"
  );
  if(active!=="dashboard"){
    return (
      active && <div>
         <nav className="navbar navbar-expand-lg navbar-light bg-light bg-body-tertiary">
           <div className="container-fluid">
             <Link className="navbar-brand" to="/"><img alt='logo' width={40} src='cctvquote.webp'/></Link>
             <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
             </button>
             <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
               <ul className="navbar-nav">
                 <li className="nav-item" style={{visibility:"visible"}}>
                   <Link className={active==="home"?"nav-link active":"nav-link"} to="/" onClick={()=>setactive("home")}>Home</Link>
                 </li>
                 <li className="nav-item" style={{visibility:"visible"}}>
                   <Link className={active==="signup"?"nav-link active":"nav-link"} to="/signup" onClick={()=>setactive("signup")}>Signup</Link>
                 </li>
               </ul>
             </div>
           </div>
           </nav>
       </div>
     );
  }
}

export default BasicNavbar;