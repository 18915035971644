import { firestore } from 'Backend/config'
import { collection, doc, getDocs, query, updateDoc, where, } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

export default function Price_Update() {
    const [data,setdata] = useState([])
    useEffect(() => {
        function getdata() {
          var arr = []
          getDocs(query(collection(firestore,'Products'),where('Type','!=','Accessories'))).then((res)=>{
            res.docs.forEach((docref)=>{
                getDocs(collection(firestore,'Products/'+docref.id+"/"+docref.id)).then((res)=>{
                  res.docs.forEach((doc)=>{
                    arr.push({id:doc.id,...doc.data(),Type:docref.id})
                    setdata(arr)
                  })
                })
            })
        })
        }
        getdata()
    },[])
    function update(type,id) {
      Swal.fire({
        title: 'Enter the Price',
        input: 'number',
        inputPlaceholder: 'Enter price here',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        preConfirm: (value) => {
            if (!value || value <= 0) {
                Swal.showValidationMessage('Please enter a valid price');
            } else {
                return value;
            }
        }
    }).then((result) => {
        if (result.isConfirmed) {
            const price = result.value;
            console.log(type,id,price)
            updateDoc(doc(firestore, 'Products/'+ type +"/"+type+"/"+ id), {
              Model_Price: price
            })
            .then(() => {
              const index = data.findIndex(obj => obj.id === id);
              const selectedRow = data[index];
              selectedRow.Model_Price = price;
              const newRow = [...data.slice(0, index), selectedRow, ...data.slice(index + 1)];
              setdata(newRow);
              Swal.fire({
                icon: 'success',
                title: 'Price Updated',
                showConfirmButton: false,
                timer: 1500
              })
            })
        }
    });
};
  return (
    <div>
        <br />
      <div className="row">
        <div className="col-xs-12 col-sm-6 text-center">
            Price Update
        </div>
        <div className="col-xs-12 col-sm-6">
            <button className='btn btn-primary' onClick={()=>{Swal.fire({title: 'Are you sure?',
                icon: 'warning',
                confirmButtonText: 'Yes, go back '}).then((result) => {if (result.isConfirmed) {history.back()}})}}>Go Back</button>

        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-12">
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Model Name</th>
                            <th>Model Price</th>
                            <th>Model Type</th>
                            <th>Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length!==0?data.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{item.Model_Name}</td>
                                    <td>{item.Model_Price}</td>
                                    <td>{item.Type}</td>
                                    <td><button className='btn btn-primary' onClick={()=>{update(item.Type,item.id)}}>Update Price</button></td>
                                </tr>
                            )
                        }):null}
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  )
}