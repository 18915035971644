import React, { useState,useEffect } from 'react'
import { getDocs,collection } from 'firebase/firestore'
import { firestore } from 'Backend/config'
import Swal from 'sweetalert2';
export default function Monitor() {
    var [moni,setmoni] = useState([])
    function Back() {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure that you want to go back?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go back!'
        }).then((result) => {
          if (result.isConfirmed) {
            history.back()
          }
        })
      }
      function open(url){
        Swal.fire({
            imageUrl: url,
          });
    }
    useEffect(() => {
        getDocs(collection (firestore, `Products/Monitor/Monitor`)).then((snapshot) => {
          var set = []
          snapshot.docs.forEach((doc) => {
            var data = doc.data()
            data.id = doc.id
            set.push(data)
          })
          setmoni(set)
        })
      },[])
    return (
        <>
        <div className='row' style={{width:'90%',marginLeft: '5%'}}>
        <div className='col-sm-6' style={{marginBottom:'10px',marginTop:'20px'}}>
            <h1>View Monitor</h1>
        </div>
        <div className='col-sm-6' style={{marginTop:'30px',marginBottom:'20px'}}>
            <button className='btn btn-danger' onClick={()=>{Back()}}>Go Back</button>
        </div>
        </div>
       <table style={{width:'90%',marginLeft: '5%'}}>
      <thead>
        <tr>
          <th scope="col">Model Name</th>
          <th scope="col">Model Price</th>
          <th scope="col">Monitor Size</th>
          <th scope="col">Company</th>
          <th scope="col">Supported Cable</th>
          <th scope="col">PDF</th>
          <th scope="col">Monitor Picture</th>
        </tr>
      </thead>
      <tbody>
        <React.Fragment>
        {moni && moni.map((cam2) => {       
             return(<tr key={cam2.id}>
             <td data-label="Model Name">{cam2.Model_Name}</td>
             <td data-label="Model Price">{cam2.Model_Price}</td>
             <td data-label="Monitor Size">{cam2.Monitor_Size}</td>
             <td data-label="Company">{cam2.Company}</td>
             <td data-label="Supported Cable">{cam2.Supported_Cable}</td>
             <td data-label="Download"><button className="btn btn-primary" onClick={()=>{window.open(cam2.PDF,"_blank")}}>Download</button></td>
             <td data-label="PSU Picture"><button className="btn btn-primary" onClick={()=>{open(cam2.Monitor_Picture)}}>View Image</button></td></tr>)
        })
        }
        </React.Fragment>
      </tbody>
    </table>
        </>
      )
}
