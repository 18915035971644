import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "Backend/config";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Chart } from "chart.js/auto";

export default function AdminStructure(){
  const [data, setData] = useState(undefined);
  const [data2, setData2] = useState(undefined);
  const [data3, setData3] = useState(undefined);
  const [data4, setData4] = useState(undefined);
  const getLastThreeDates = () => {
    const today = new Date();
    const lastThreeDates = [];
    for (let i = 0; i < 3; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        lastThreeDates.push(date.toDateString());
    }
    return lastThreeDates.reverse();
};
  useEffect(() => {
    const fetchLastLoginData = async () => {
      const usersCol = query(collection(firestore, "Users"),where("Access","==","User"));
      const userSnapshot = await getDocs(usersCol);      
    if(userSnapshot.docs.lenght  !== 0){
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        lastLogin: doc.data().Last_Login?.toDate(), 
      }));

      const currentDate = new Date();
      const threeDaysAgo = new Date(currentDate);
      threeDaysAgo.setDate(currentDate.getDate() - 3);
      const counts = {};
      userList.forEach(user => {
        if (user.lastLogin >= threeDaysAgo) {
          const loginDate = user.lastLogin.toDateString();
          counts[loginDate] = counts[loginDate] ? counts[loginDate] + 1 : 1;
        }
      });
      const formattedData = [];
      for (let i = 0; i < 3; i++) {
        const date = new Date();
        date.setDate(currentDate.getDate() - i);
        formattedData.push({
          x: date.toDateString(),
          y: counts[date.toDateString()] || 0,
        });
      }            
      var data = formattedData.reverse()
      var x = [];
      var y = [];
      data.forEach((element) => {
        x.push(element.x);
        y.push(element.y);
      })      
      setData([x,y]);
    }
    };
    const fetchLastLoginData2 = async () => {
      const usersCol = query(collection(firestore, "Users"),where("Access","==","Employee"));
      const userSnapshot = await getDocs(usersCol);      
    if(userSnapshot.docs.lenght  !== 0){
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        lastLogin: doc.data().Last_Login?.toDate(), 
      }));

      const currentDate = new Date();
      const threeDaysAgo = new Date(currentDate);
      threeDaysAgo.setDate(currentDate.getDate() - 3);
      const counts = {};
      userList.forEach(user => {
        if (user.lastLogin >= threeDaysAgo) {
          const loginDate = user.lastLogin.toDateString();
          counts[loginDate] = counts[loginDate] ? counts[loginDate] + 1 : 1;
        }
      });
      const formattedData = [];
      for (let i = 0; i < 3; i++) {
        const date = new Date();
        date.setDate(currentDate.getDate() - i);
        formattedData.push({
          x: date.toDateString(),
          y: counts[date.toDateString()] || 0,
        });
      }            
      var data = formattedData.reverse()
      var x = [];
      var y = [];
      data.forEach((element) => {
        x.push(element.x);
        y.push(element.y);
      })      
      setData2([x,y]);
    }
    };
    const fetchLastLoginData3 = async () => {
      const usersCol = query(collection(firestore, "Users"),where("Access","==","User"));
      const userSnapshot = await getDocs(usersCol);      
    if(userSnapshot.docs.lenght  !== 0){
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        lastLogin: doc.data().Creation_Time?.toDate(), 
      }));
      const currentDate = new Date();
      const threeDaysAgo = new Date(currentDate);
      threeDaysAgo.setDate(currentDate.getDate() - 3);
      const counts = {};
      userList.forEach(user => {
        if (user.lastLogin >= threeDaysAgo) {
          const loginDate = user.lastLogin.toDateString();
          counts[loginDate] = counts[loginDate] ? counts[loginDate] + 1 : 1;
        }
      });
      const formattedData = [];
      for (let i = 0; i < 3; i++) {
        const date = new Date();
        date.setDate(currentDate.getDate() - i);
        formattedData.push({
          x: date.toDateString(),
          y: counts[date.toDateString()] || 0,
        });
      }            
      var data = formattedData.reverse()
      var x = [];
      var y = [];
      data.forEach((element) => {
        x.push(element.x);
        y.push(element.y);
      })      
      setData3([x,y]);
    }
    };
    const fetchLastLoginData4 = async () => {
      const usersCol = query(collection(firestore, "Users"),where("Access","==","Employee"));
      const userSnapshot = await getDocs(usersCol);      
    if(userSnapshot.docs.lenght  !== 0){
      console.log(userSnapshot.docs);
      
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        lastLogin: doc.data().Creation_Time?.toDate(), 
      }));
      const currentDate = new Date();
      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(currentDate.getDate() - 3);
      const counts = {};
      userList.forEach(user => {
        if (user.lastLogin >= threeDaysAgo) {
          const loginDate = user.lastLogin.toDateString();
          counts[loginDate] = counts[loginDate] ? counts[loginDate] + 1 : 1;
        }
      });
      const formattedData = [];
      for (let i = 0; i < 3; i++) {
        const date = new Date();
        date.setDate(currentDate.getDate() - i);
        formattedData.push({
          x: date.toDateString(),
          y: counts[date.toDateString()] || 0,
        });
      }                  
      var data = formattedData.reverse()
      var x = [];
      var y = [];
      data.forEach((element) => {
        x.push(element.x);
        y.push(element.y);
      })      
      setData4([x,y]);
    }
    };
    fetchLastLoginData4()
    fetchLastLoginData3()
    fetchLastLoginData2()
    fetchLastLoginData();
    
  }, []);
  Chart.register(CategoryScale);  
  function getchartdata(labels, data, title) {
    return {
      labels,
      datasets: [{
        label: title,
        data,
      }]
    }
  }
  return (
    <div style={{maxWidth:"90%",margin:"auto"}}>
    <br />
      <div className="row pr-6">
      <div className="col-sm-6">
        {data !== undefined ? <Line data={getchartdata(data !== undefined?data[0]:getLastThreeDates(),data !== undefined?data[1]:[0,0,0],"Users Logins")}/> : null}
      </div>
      <div className="col-sm-6">
        {data !== undefined ? <Line data={getchartdata(data2 !== undefined?data2[0]:getLastThreeDates(),data2 !== undefined?data2[1]:[0,0,0],"Employee Logins")}/> : null}
      </div>
    </div>
    <br />
    <hr  className="w-90"/>
    <br />
    <div className="row pr-6">
    <div className="col-sm-6">
      {data !== undefined ? <Line data={getchartdata(data3 !== undefined?data3[0]:getLastThreeDates(),data3 !== undefined?data3[1]:[0,0,0],"Users Signups")}/> : null}
    </div>
    <div className="col-sm-6">
      {data !== undefined ? <Line data={getchartdata(data4 !== undefined?data4[0]:getLastThreeDates(),data4 !== undefined?data4[1]:[0,0,0],"Employee Signups")}/> : null}
    </div>
  </div>
    </div>
  );
};