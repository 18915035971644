import errormessage from "components/Main/error";

export default function addcamera(camera,func){
    if(document.getElementById('Quantity').value !== '0'){
      if(document.getElementById('Camera_Model').value !== ''){
        var condition = true
        var index;
      document.getElementById('tbody').querySelectorAll('tr').forEach((element)=>{
        var Name = document.getElementById('Camera_Model').value
        for (var i = 0; i < camera.length; i++) {
          if(camera[i].Model_Name === Name){
            condition = false
            index = i
          }
        }
      })
      if(condition){
        var row = document.createElement('tr')
      var td1 = document.createElement('td')
      var td2 = document.createElement('td')
      var td3 = document.createElement('td')
      var td4 = document.createElement('td')
      var td5 = document.createElement('td')
      var td6 = document.createElement('td')
      var td7 = document.createElement('td')
      var td8 = document.createElement('td')
      td8.onclick = ()=>{
        if(camera.length!==1){
          row.remove()
        camera.splice(index,1)
        }else{
          errormessage("Atleast One Camera should be selected")
        }
      }
      td8.innerHTML = `<i class="fas fa-trash"></i>`
      td1.innerHTML = document.getElementById('Camera_Type').value
      td2.innerHTML = document.getElementById('Camera_Resolution').value
      td3.innerHTML = document.getElementById('Camera_Lens').value
      td4.innerHTML = document.getElementById('Camera_Model').value
      td5.innerHTML = document.getElementById('Price').innerHTML
      td6.innerHTML = document.getElementById('Quantity').value
      td7.innerHTML = ((td5.innerHTML)*(td6.innerHTML))
      row.append(td1,td2,td3,td4,td5,td6,td7,td8)
      document.querySelector('#tbody').append(row)
      document.getElementById('Quantity').value = 0
      document.getElementById('Amount').innerHTML = 0
      camera.push({Model_Name: td4.innerHTML,Price: td5.innerHTML,Camera_Type: td1.innerHTML,Camera_Resolution: td2.innerHTML,Camera_Lens: td3.innerHTML,Quantity: td6.innerHTML,Amount: td7.innerHTML})
      }else{
        camera[index].Quantity = parseInt(camera[index].Quantity) + parseInt(document.getElementById('Quantity').value)
        document.getElementById('tbody').querySelectorAll('tr').forEach((element)=>{
          var Name = element.querySelectorAll('td')[3].textContent
          if(Name === camera[index].Model_Name){
            element.querySelectorAll('td')[5].textContent = parseInt(element.querySelectorAll('td')[5].textContent) + parseInt(document.getElementById('Quantity').value)
            element.querySelectorAll('td')[6].textContent = ((element.querySelectorAll('td')[5].textContent)*(element.querySelectorAll('td')[6].textContent))
            document.getElementById('Quantity').value = "0"
          }
        })
      }
      func()
      }else{
        errormessage("Please Select Model")
      }
    }else{
      errormessage("Please Enter Quantity")
    }
  }
