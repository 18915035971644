import addproduct from 'Backend/Firestore/upload'
import upload from 'Backend/Storage/upload'
import validateproduct from 'Backend/Firestore/validate'
import React from 'react'
import Swal from 'sweetalert2'

export default function Camera() {
  const css = `
  .form-control{
        width: 99%;
    }
    body{
        overflow-x: hidden;
    }
    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  `
  function Back() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure that you want to go back?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, go back!'
    }).then((result) => {
      if (result.isConfirmed) {
        history.back()
      }
    })
  }
  function validate(callback){
    if(document.getElementById('Model_Name').value === ""){
      alert('Please Enter Model Name')
    }
    else if(document.getElementById('Model_Price').value === ""){
      alert('Please Enter Model Price')
    }else if(document.getElementById('PDF').value === ""){
      alert('Please Upload PDF')
    }else if(document.getElementById('Camera_Picture').value === ""){
      alert('Please Upload picture')
    }else{
      callback()
    }
  }
  function Upload(){
    validate(()=>{
      validateproduct('Camera',document.getElementById('Model_Name').value,()=>{
        var pdf = document.getElementById('PDF').files[0]
        var image = document.getElementById('Camera_Picture').files[0]
        upload('Camera',document.getElementById('Model_Name').value,pdf,image,(pdfurl,imageurl)=>{
          addproduct(document.getElementById('Available').value,'Camera',{
            Model_Name:document.getElementById('Model_Name').value,
            Model_Price:document.getElementById('Model_Price').value,
            Company:document.getElementById('Company').value,
            Camera_Series:document.getElementById('Camera_Series').value,
            Camera_Type:document.getElementById('Camera_Type').value,
            Camera_Resolution:document.getElementById('Camera_Resolution').value,
            Camera_Lens:document.getElementById('Camera_Lens').value,
            Audio: document.getElementById('Audio').value ? true : false,
            PDF: pdfurl,
            Camera_Picture: imageurl
          })
        })
        })
    })
  }
  return (
    <>
    <style>
      {css}
    </style>
    <center><h1>Add Camera</h1></center>
     <div className="row" style={{width:'90%',marginLeft: '5%'}}>
            <div className="col-xs-12 col-sm-6"><label htmlFor="Model_Name" className="form-label" style={{fontWeight: "bold"}}>Model Name</label>
                <input type="text" id="Model_Name" data-name="Model_Name" required className="form-control"/>
                <label htmlFor="Model_Price" className="form-label" style={{fontWeight: "bold"}}>Model Price</label>
                <input type="number" id="Model_Price" data-name="Model_Price" required className="form-control"/>
                <label htmlFor="Company" className="form-label" style={{fontWeight: "bold"}}>Company</label>
                <select data-name="Company" id="Company" className="form-control">
                    <option defaultValue="HIKVISION">HIKVISION</option>
                    <option defaultValue="CPPLUS">CPPLUS</option>
                    <option defaultValue="PRAMA">PRAMA</option>
                </select>
                <label htmlFor="Camera_Series" className="form-label" style={{fontWeight: "bold"}}>Camera Series</label>
                <select data-name="Camera_Series" id="Camera_Series" className="form-control" onChange={(e) => e.target.value==='IP'?document.getElementById('Camera_Resolution').innerHTML = '<option>1.3MP</option><option>2MP</option><option>4MP</option><option>6MP</option><option>8MP</option>':document.getElementById('Camera_Resolution').innerHTML = '<option>1MP</option><option>2MP</option><option>4MP</option><option>5MP</option><option>6MP</option><option>8MP</option>'}>
                    <option defaultValue="HD">HD</option>
                    <option defaultValue="IP" defaultChecked>IP</option>
                    </select>
                    <label htmlFor="Camera_Type" className="form-label" style={{fontWeight: "bold"}}>Camera Type</label>
                    <select data-name="Camera_Type" id="Camera_Type" className="form-control">
                        <option defaultValue="Dome">Dome</option>
                        <option defaultValue="Bullet">Bullet</option>
                    </select>
                </div>
            <div className="col-xs-12 col-sm-6">
                <label htmlFor="Camera_Resolution" className="form-label" style={{fontWeight: "bold"}}>Camera Resolution</label>
                <select data-name="Camera_Resolution" id="Camera_Resolution" className="form-control">
                    <option defaultValue="1MP">1MP</option>
                    <option defaultValue="2MP">2MP</option>
                    <option defaultValue="4MP">4MP</option>
                    <option defaultValue="5MP">5MP</option>
                    <option defaultValue="6MP">6MP</option>
                    <option defaultValue="8MP">8MP</option>
                </select>
                <label htmlFor="Camera_Lens" className="form-label" style={{fontWeight: "bold"}}>Camera Lens</label>
                <select data-name="Camera_Lens" id="Camera_Lens" className="form-control">
                    <option defaultValue="10M">10M</option>
                    <option defaultValue="30M">20M</option>
                    <option defaultValue="50M">25M</option>
                    <option defaultValue="20M">30M</option>
                    <option defaultValue="25M">40M</option>
                    <option defaultValue="40M">50M</option>
                    <option defaultValue="80M">80M</option>
                </select>
                <label htmlFor="Audio" className="form-label" style={{fontWeight: "bold"}}>Audio</label>
                <select data-name="Audio" id="Audio" className="form-control">
                    <option defaultValue="Yes">Yes</option>
                    <option defaultValue="No">No</option>
                </select>
                <label htmlFor="Available" className="form-label" style={{fontWeight: "bold"}}>Available</label>
                    <select id="Available" className="form-control">
                        <option defaultValue="Yes">Yes</option>
                        <option defaultValue="No">No</option>
                    </select>
                <label htmlFor="PDF" className="form-label" style={{fontWeight: "bold"}}>PDF</label>
                <input type="file" id="PDF" data-name="PDF" accept="application/pdf" className="form-control"/>
                <label htmlFor="Camera_Picture" className="form-label" style={{fontWeight: "bold"}}>Camera Picture</label>
                <input type="file" id="Camera_Picture" data-name="Camera_Picture" accept="image/*" className="form-control"/>
        </div>
    </div> 
    <br />
    <div className='row' style={{width: '90%',marginLeft: '5%'}}>
        <div className='col-sm-12 col-xs-12'style={{textAlign: 'center',display:'flex',justifyContent:' center',alignItems:'center'}}>
        <button className="btn btn-primary" type="button" onClick={()=>{Upload()}} style={{float: 'right',marginRight: "10px"}}>Save</button>
        <button type="button" className="btn btn-danger" onClick={() => Back()}>Back to Products</button>
        </div>
        </div>
    </>
  )
}
