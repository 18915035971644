import { getDocs,collection, query, orderBy, doc, updateDoc } from 'firebase/firestore'
import { firestore } from 'Backend/config'
import React,{useEffect,useState} from 'react'
import Swal from 'sweetalert2'
import editfile from "Backend/Storage/update"
export default function Camera() {
    function Back() {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure that you want to go back?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go back!'
        }).then((result) => {
          if (result.isConfirmed) {
            history.back()
          }
        })
      }
    var [cam,setcam] = useState(false)
    useEffect(() => {
      getDocs(query(
        collection (firestore, `Products/Camera/Camera`),
        orderBy('Camera_Resolution','desc')
      )).then((snapshot) => {
        var set = []
        snapshot.docs.forEach((doc) => {
          var data = doc.data()
          data.id = doc.id
          set.push(data)
        })
        setcam(set)
      })
    },[])
    function audio(id,audio){
        Swal.fire({
          title: 'Are you sure?',
          text: `Are you sure to ${audio ? 'remove' : 'add'} audio?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, edit audio!'
        }).then((result) => {
          if (result.isConfirmed) {
            const ref = doc(firestore, `Products/Camera/Camera/${id}`);
            updateDoc(ref, {
              Audio: !audio
            }).then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Audio changed successfully!',
                })
            }).then(() => {
                    document.getElementById('table').querySelectorAll('tr').forEach((element) => {                      
                      if(element.classList[0] === id){
                        element.querySelectorAll('td')[2].innerHTML = !audio ? '<span class="text-success">Yes</span>' : '<span class="text-danger">No</span>'
                      }
                    })
            })
          }
        })
    }
  return (
    <>
    <div className='row' style={{width:'90%',marginLeft: '5%'}}>
    <div className='col-sm-4' style={{marginBottom:'10px',marginTop:'20px'}}>
        <h1>View Camera</h1>
    </div>
    <div className='col-sm-4' style={{marginTop:'30px',marginBottom:'20px'}}>
    <h3>Total Numbers of Camera: {cam && cam.length}</h3>
    </div>
    <div className='col-sm-4' style={{marginTop:'30px',marginBottom:'20px'}}>
        <button className='btn btn-danger' style={{float:'right'}} onClick={()=>{Back()}}>Go Back</button>
    </div>
    </div>
   <table style={{width:'90%',marginLeft: '5%'}}>
  <thead>
    <tr>
      <th scope="col">Model Name</th>
      <th scope="col">Audio</th>
      <th scope="col">Edit Audio</th>
      <th scope="col">PDF</th>
      <th scope="col">Picture</th>
    </tr>
  </thead>
  <tbody id='table'>
    <React.Fragment>
    {cam && cam.map((cam2) => {       
         return(
         <tr key={cam2.id} className={cam2.id}>
            <td data-label="Model Name">{cam2.Model_Name}</td>
            <td data-label="Edit Audio"><button className="btn btn-primary" onClick={()=>{audio(cam2.id,cam2.Audio)}}>Edit Audio</button></td>
            <td data-label="Audio" className='audio-bolean'>{cam2.Audio?<span className="text-success">Yes</span>:<span className="text-danger">No</span>}</td>
            <td data-label="PDF"><button className="btn btn-primary" onClick={()=>{editfile("pdf","Camera",cam2.id,cam2.Model_Name,cam2.PDF)}}>Edit PDF</button></td>
            <td data-label="Camera Picture"><button className="btn btn-primary" onClick={()=>{editfile("image","Camera",cam2.id,cam2.Model_Name,cam2.Camera_Picture)}}>Edit Image</button></td>
            </tr>)
         })}
    </React.Fragment>
  </tbody>
</table>
    </>
  )
}
