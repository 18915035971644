import { getDocs,collection, query, orderBy } from 'firebase/firestore'
import { firestore } from 'Backend/config'
import React,{useEffect,useState} from 'react'
import Swal from 'sweetalert2'
export default function Camera() {
    function Back() {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure that you want to go back?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go back!'
        }).then((result) => {
          if (result.isConfirmed) {
            history.back()
          }
        })
      }
    var [cam,setcam] = useState(false)
    useEffect(() => {
      getDocs(query(
        collection (firestore, `Products/Camera/Camera`),
        orderBy('Camera_Resolution','desc')
      )).then((snapshot) => {
        var set = []
        snapshot.docs.forEach((doc) => {
          var data = doc.data()
          data.id = doc.id
          set.push(data)
        })
        setcam(set)
      })
    },[])
    function open(url){
        Swal.fire({
            imageUrl: url,
          });
    }
  return (
    <>
    <div className='row' style={{width:'90%',marginLeft: '5%'}}>
    <div className='col-sm-4' style={{marginBottom:'10px',marginTop:'20px'}}>
        <h1>View Camera</h1>
    </div>
    <div className='col-sm-4' style={{marginTop:'30px',marginBottom:'20px'}}>
    <h3>Total Numbers of Camera: {cam && cam.length}</h3>
    </div>
    <div className='col-sm-4' style={{marginTop:'30px',marginBottom:'20px'}}>
        <button className='btn btn-danger' style={{float:'right'}} onClick={()=>{Back()}}>Go Back</button>
    </div>
    </div>
   <table style={{width:'90%',marginLeft: '5%'}}>
  <thead>
    <tr>
      <th scope="col">Model</th>
      <th scope="col">Price</th>
      <th scope="col">Company</th>
      <th scope="col">Resolution</th>
      <th scope="col">Type</th>
      <th scope="col">Series</th>
      <th scope="col">Lens</th>
      <th scope="col">PDF</th>
      <th scope="col">Picture</th>
    </tr>
  </thead>
  <tbody>
    <React.Fragment>
    {cam && cam.map((cam2) => {       
         return(<tr key={cam2.id}><td data-label="Model Name">{cam2.Model_Name}</td><td data-label="Model Price">{cam2.Model_Price}</td><td data-label="Company">{cam2.Company}</td><td data-label="Camera Resolution">{cam2.Camera_Resolution}</td><td data-label="Camera Type">{cam2.Camera_Type}</td><td data-label="Camera Series">{cam2.Camera_Series}</td><td data-label="Camera Lens">{cam2.Camera_Lens}</td><td data-label="PDF"><button className="btn btn-primary" onClick={()=>{window.open(cam2.PDF)}}>Download</button></td><td data-label="Camera Picture"><button className="btn btn-primary" onClick={()=>{open(cam2.Camera_Picture)}}>View Image</button></td></tr>)
         })}
    </React.Fragment>
  </tbody>
</table>
    </>
  )
}
