import errormessage from "components/Main/error";

export default function addaccessiors(arr,func) {
    var condition = true
    var index;
  document.getElementById('tbody').querySelectorAll('tr').forEach((element)=>{
    var Name = document.getElementById('Model_Name7').value
    for (var i = 0; i < arr.length; i++) {
      if(arr[i].Model_Name === Name){
        condition = false
        index = i
      }
    }
  })
  if(condition){
    var row = document.createElement('tr')
  var td1 = document.createElement('td')
  var td2 = document.createElement('td')
  var td3 = document.createElement('td')
  var td4 = document.createElement('td')
  var td5 = document.createElement('td')
  td5.onclick = ()=>{
    if(arr.length !== 1){
      row.remove()
    arr.splice(index,1)
    }else{
      errormessage("Atleast One Accessiors should be selected")
    }
  }
  td1.innerHTML = document.getElementById('Model_Name7').value
  td2.innerHTML = document.getElementById('Price7').value
  td3.innerHTML = document.getElementById('Quantity7').value
  td4.innerHTML = ((td2.innerHTML)*(td3.innerHTML))
  td5.innerHTML = '<i class="fas fa-trash"></i>'
  row.append(td1,td2,td3,td4,td5)
  document.querySelector('.Accessories-tbody').append(row)
  document.getElementById('Quantity7').value = 1
  document.getElementById('Amount7').innerHTML = 0
  arr.push({
    Model_Name: td1.innerHTML,
    Price: td2.innerHTML,
    Quantity: td3.innerHTML,
    Amount: td4.innerHTML
  })
  }else{
    arr[index].Quantity = parseInt(arr[index].Quantity) + parseInt(document.getElementById('Quantity7').value)
    document.querySelector('.Accessories-tbody').querySelectorAll('tr').forEach((element)=>{
      var Name = element.querySelectorAll('td')[0].innerHTML
      if(Name === arr[index].Model_Name){
        element.querySelectorAll('td')[1].textContent = document.getElementById('Price7').value
        element.querySelectorAll('td')[2].textContent = (parseInt(element.querySelectorAll('td')[2].textContent) + parseInt(document.getElementById('Quantity7').value))
        element.querySelectorAll('td')[3].textContent = (parseInt(document.getElementById('Price7').value)*(parseInt(element.querySelectorAll('td')[2].textContent)))
        document.getElementById('Quantity7').value = "1"
      }
    })
  }
  func(arr)
}
