import { getDocs,collection } from 'firebase/firestore'
import { firestore } from 'Backend/config'
import React,{useEffect,useState} from 'react'
import Swal from 'sweetalert2'
export default function Accessories() {
    const [Accessories,setAccessories] = useState([])
    function Back() {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure that you want to go back?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go back!'
        }).then((result) => {
          if (result.isConfirmed) {
            history.back()
          }
        })
      }
      useEffect(() => {
        getDocs(collection (firestore, `Products/Accessories/Accessories`)).then((snapshot) => {
          var set = []
          snapshot.docs.forEach((doc) => {
            var data = doc.data()
            data.id = doc.id
            set.push(data)
          })
          setAccessories(set)
        })
      },[])
      function open(url){
        Swal.fire({
            imageUrl: url,
          });
    }
  return (
    <>
    <div className='row' style={{width:'90%',marginLeft: '5%'}}>
    <div className='col-sm-6' style={{marginBottom:'10px',marginTop:'20px'}}>
        <h1>View Accessories</h1>
    </div>
    <div className='col-sm-6' style={{marginTop:'30px',marginBottom:'20px'}}>
        <button className='btn btn-danger' onClick={()=>{Back()}}>Go Back</button>
    </div>
    </div>
   <table style={{width:'90%',marginLeft: '5%'}}>
  <thead>
    <tr>
      <th scope="col">Model Name</th>
      <th scope="col">Accessories Picture</th>
    </tr>
  </thead>
  <tbody>
    <React.Fragment>
    {Accessories && Accessories.map((cam2) => {       
         return(<tr key={cam2.id}>
         <td data-label="Model Name">{cam2.Model_Name}</td>
         <td data-label="Accessories Picture"><button className="btn btn-primary" onClick={()=>{open(cam2.Image)}}>View Image</button></td></tr>)
    })
    }
    </React.Fragment>
  </tbody>
</table>
    </>
  )
}
