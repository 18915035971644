import { getDocs,collection } from 'firebase/firestore'
import { firestore } from 'Backend/config'
import React,{useEffect,useState} from 'react'
import Swal from 'sweetalert2'
export default function NVR() {
    function Back() {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure that you want to go back?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go back!'
        }).then((result) => {
          if (result.isConfirmed) {
            history.back()
          }
        })
      }
    var [cam,setcam] = useState(false)
    useEffect(() => {
      getDocs(collection (firestore, `Products/NVR/NVR`)).then((snapshot) => {
        var set = []
        snapshot.docs.forEach((doc) => {
          var data = doc.data()
          data.id = doc.id
          set.push(data)
        })
        setcam(set)
      })
    },[])
    function open(url){
        Swal.fire({
            imageUrl: url,
          });
    }
    function show(arr){
        var table  = `
        <table style='width:'90%',marginLeft: '5%'>
        <thead>
          <tr>
            <th scope="col">Model Name</th>
            <th scope="col">ID</th>
          </tr>
        </thead>
        <tbody>
        ${arr.map((item) => {return(`<td data-label="Model Name">${item.name}</td><td data-label="Model ID">${item.id}</td></tr>`)})}
        </tbody>
        </table>
        `
        
        Swal.fire({html: table})
    }
  return (
    <>
    <div className='row' style={{width:'90%',marginLeft: '5%'}}>
    <div className='col-sm-6' style={{marginBottom:'10px',marginTop:'20px'}}>
        <h1>View NVR</h1>
    </div>
    <div className='col-sm-6' style={{marginTop:'30px',marginBottom:'20px'}}>
        <button className='btn btn-danger' onClick={()=>{Back()}}>Go Back</button>
    </div>
    </div>
   <table style={{width:'90%',marginLeft: '5%'}}>
  <thead>
    <tr>
      <th scope="col">Model Name</th>
      <th scope="col">Model Price</th>
      <th scope="col">Company</th>
      <th scope="col">Supported Camera</th>
      <th scope="col">PDF</th>
      <th scope="col">NVR Picture</th>
    </tr>
  </thead>
  <tbody>
    <React.Fragment>
    {cam && cam.map((cam2) => {       
         return(<tr key={cam2.id}>
         <td data-label="Model Name">{cam2.Model_Name}</td>
         <td data-label="Model Price">{cam2.Model_Price}</td>
         <td data-label="Company">{cam2.Company}</td>
         <td data-label="Supported Camera"><button className='btn btn-primary' onClick={()=>{show(cam2.Supported_Cameras)}}>View Camera</button></td>
         <td data-label="PDF"><button className="btn btn-primary" onClick={()=>{window.open(cam2.PDF)}}>Download</button></td>
         <td data-label="NVR Picture"><button className="btn btn-primary" onClick={()=>{open(cam2.NVR_Picture)}}>View Image</button></td></tr>)
    })
    }
    </React.Fragment>
  </tbody>
</table>
    </>
  )
}
