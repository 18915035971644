import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useAuth } from 'Backend/Authentication/Context';
import { doSignInWithEmailAndPassword } from 'Backend/Authentication/functions';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from 'Backend/config';
import { BsEye, BsEyeSlash } from 'react-icons/bs'; // Bootstrap-styled icons for password visibility

const Login = () => {
    const { currentUser } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [error, setError] = useState('');

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        setIsSigningIn(true);
        try {
            const cred = await doSignInWithEmailAndPassword(email, password);
            await updateDoc(doc(firestore, `Users/${cred.user.uid}`), { Last_Login: new Date() });
            Swal.fire({
                icon: 'success',
                title: 'Login Successful',
                showConfirmButton: false,
                timer: 4000
            }).then(() => {
                window.location.href = '/dashboard';
            })
        } catch (err) {
            setError(err.message);
            Swal.fire({
                icon: 'error',
                title: 'Login Failed',
                text: err.message,
                showConfirmButton: false,
                timer: 2000
            });
        } finally {
            setIsSigningIn(false);
        }
    };
    useEffect(() => {
        if (currentUser?.user) {
            window.location.href = '/dashboard';
        }
    }, [])
    return (
        <div className='container mt-5'>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    <img src="cctvquote.webp" alt="Company Logo" className="mb-4" style={{ display: 'block', margin: '0 auto', maxWidth: '200px' }}/>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form onSubmit={handleLogin}>
                        <div className="mb-3">
                            <label htmlFor="Email" className="form-label" style={{ fontWeight: 'bold' }}>Email</label>
                            <input type="email" className="form-control" id="Email" value={email} onChange={e => setEmail(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label" style={{ fontWeight: 'bold' }}>Password</label>
                            <div className="input-group">
                                <input type={passwordShown ? 'text' : 'password'} className="form-control" id="password" value={password} onChange={e => setPassword(e.target.value)} autoComplete='on' required />
                                <button type="button" className="btn btn-outline-secondary" onClick={togglePasswordVisibility}>
                                    {passwordShown ? <BsEyeSlash /> : <BsEye />}
                                </button>
                            </div>
                        </div>
                        <button type='submit' disabled={isSigningIn} className="btn btn-primary w-100">
                            {isSigningIn ? 'Signing In...' : 'Sign In'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
