import { useAuth } from 'Backend/Authentication/Context'
import { firestore } from 'Backend/config'
import { collection, getCountFromServer, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

export default function Structure() {
  const {currentUser} = useAuth()
  const [users,setusers] = useState([])
  useEffect(() => {
    getDocs(query(collection(firestore,"Users"),where("Access","==","Employee"),where("CreatedByUID","==",currentUser.user.uid))).then((data)=>{
      var arr = []
      data.docs.forEach((doc)=>{
        arr.push({...doc.data(),id:doc.id})
      })
      setusers(arr)
    })
  },[])
  return (
    <div>
      {users.map((data,index)=>{
        return (
          <div className="col-md-4" key={index}>
             <div className="card mb-3" style={{ width: '18rem' }}>
            <div className="card-header bg-primary text-white">
                {index+1}. {data.Employee_Name}
            </div>
            <div className="card-body">
                <p className="card-text"><strong>Phone:</strong> {data.Contact_No}</p>
                <p className="card-text"><strong>Email:</strong> {data.Email}</p>
                <p className="card-text"><strong>Last Login:</strong> {data.Last_Login.toDate().toLocaleString()}</p>
                <p className="card-text"><strong>Created:</strong> {data.Creation_Time.toDate().toLocaleString()}</p>
                <button className="btn btn-primary" onClick={()=>{
                  getCountFromServer(query(collection(firestore, "CCTV_Quotations"),where("Email","==",data.Email))).then((data)=>{
                    Swal.fire({title:"CCTV Quotations Count",text:data.data().count})
                  })
                }} >Get Count</button>
            </div>
        </div>
          </div>
        )
      })}
    </div>
  )
}
