import { storage } from "Backend/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
export default function upload(type,name,pdf,image,func) {
    const pdfref = ref(storage, `Products/`+type+'/'+name+'/PDF/'+name+'.pdf')
    const imageref = ref(storage, `Products/`+type+'/'+name+'/Image/'+name+'.'+(image.name.split('.').pop()))
    uploadBytes(pdfref,pdf,{contentType:'application/pdf'}).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((pdfurl) => {
            uploadBytes(imageref,image,{contentType:'image'}).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((imageurl) => {
                    func(pdfurl,imageurl)
                })
            })
        })
     })
}