import React from "react";
import { BrowserRouter,Route,Routes } from "react-router-dom";
import BasicNavbar from "./components/Main/Navbar";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import Viewcctvquote from "./components/Dashboard/Employee/viewcctvquote";
import Dashboard from "components/Dashboard/Dashboard";
import { AuthProvider } from "Backend/Authentication/Context";
import Camera from "components/Dashboard/Admin/Products/Add/Camera";
import DVR from "components/Dashboard/Admin/Products/Add/DVR";
import NVR from "components/Dashboard/Admin/Products/Add/NVR";
import PSU from "components/Dashboard/Admin/Products/Add/PSU";
import Cable from "components/Dashboard/Admin/Products/Add/Cable";
import HDD from "components/Dashboard/Admin/Products/Add/HDD";
import Monitor from "components/Dashboard/Admin/Products/Add/Monitor";
import POE from "components/Dashboard/Admin/Products/Add/POE";
import Hide from "components/Dashboard/Admin/Manage/Hide";
import UnHide from "components/Dashboard/Admin/Manage/Unhide";
import Camera2 from "components/Dashboard/Admin/Products/View/Camera";
import DVR2 from "components/Dashboard/Admin/Products/View/DVR";
import NVR2 from "components/Dashboard/Admin/Products/View/NVR";
import PSU2 from "components/Dashboard/Admin/Products/View/PSU";
import Cable2 from "components/Dashboard/Admin/Products/View/Cable";
import HDD2 from "components/Dashboard/Admin/Products/View/HDD";
import Monitor2 from "components/Dashboard/Admin/Products/View/Monitor";
import POE2 from "components/Dashboard/Admin/Products/View/POE";
import Camera3 from "components/Dashboard/Admin/Products/Edit/camera";
import Cctvquotations from "components/Dashboard/Employee/Quotations/cctvquotations";
import Accessories from "components/Dashboard/Admin/Products/Add/Accessories";
import Accessories2 from "components/Dashboard/Admin/Products/View/Accessories";
import Price_Update from "components/Dashboard/Admin/Manage/Price-Update";
function App() {
  return (
    <div>
      <AuthProvider>
      <BrowserRouter>
      <BasicNavbar />
        <Routes>
          <Route path="/" element={<Login />}/>
          <Route path="/signup" element={<Signup />}/>
          <Route path="/dashboard">
          <Route path="" index element={<Dashboard/>}/>
          <Route path="Employees" element={<Dashboard/>}></Route>
          <Route path="Profile" element={<Dashboard/>}/>
          <Route path="Users" element={<Dashboard/>}></Route>
          <Route path="Products" element={<Dashboard/>}></Route>
          <Route path="Analytics" element={<Dashboard/>}></Route>
          <Route path="Products/Add_Camera" element={<Camera/>}></Route>
          <Route path="Products/Add_DVR" element={<DVR/>}></Route>
          <Route path="Products/Add_NVR" element={<NVR/>}></Route>
          <Route path="Products/Add_PSU_SMPS" element={<PSU/>}></Route>
          <Route path="Products/Add_Cable" element={<Cable/>}></Route>
          <Route path="Products/Add_Hard_Disk" element={<HDD/>}></Route>
          <Route path="Products/Add_Monitor" element={<Monitor/>}></Route>
          <Route path="Products/Add_POE_Switch" element={<POE/>}></Route>
          <Route path="Products/Add_Accessories" element={<Accessories/>}></Route>          
          <Route path="Products/View-Camera" element={<Camera2/>} ></Route>
          <Route path="Products/View-NVR" element={<NVR2/>} ></Route>
          <Route path="Products/View-DVR" element={<DVR2/>} ></Route>
          <Route path="Products/View-PSU" element={<PSU2/>} ></Route>
          <Route path="Products/View-Cable" element={<Cable2/>} ></Route>
          <Route path="Products/View-HDD" element={<HDD2/>} ></Route>
          <Route path="Products/View-Monitor" element={<Monitor2/>} ></Route>
          <Route path="Products/View-POE" element={<POE2/>} ></Route>
          <Route path="Manage" element={<Dashboard/>}></Route>
          <Route path="Manage/Price_Update" element={<Price_Update/>}></Route>
          <Route path="Manage/Hide_Product" element={<Hide/>}></Route>
          <Route path="Manage/Unhide_Product" element={<UnHide/>}></Route>
          <Route path="Products/View-Accessories" element={<Accessories2/>} ></Route>
          <Route path="Products/Edit-Camera" element={<Camera3/>} ></Route>
          <Route path="Quotations/Add_CCTV_Quotation" element={<Cctvquotations/>}></Route>
          <Route path="Quotations/View_CCTV_Quotation" element={<Viewcctvquote/>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
