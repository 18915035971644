import Swal from "sweetalert2"
export function filter(cam){
    var html = ''
    cam.forEach(doc => {
      if(doc.Camera_Type === document.getElementById('Camera_Type').value && doc.Camera_Resolution === document.getElementById('Camera_Resolution').value && doc.Camera_Lens === document.getElementById('Camera_Lens').value){
        html += `<option value="${doc.Model_Name}">${doc.Model_Name}</option>`
      }
    })
    if(html !== ''){
      document.getElementById('Camera_Model').innerHTML = html
      document.getElementById('Quantity').disabled = false
      filter2(cam)
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No products found!'
      })
      document.getElementById('Camera_Model').innerHTML = ''
      document.getElementById('Quantity').value = 0
      document.getElementById('Quantity').disabled = true
      document.getElementById('Amount').innerHTML = 0
      document.getElementById('Price').innerHTML = ''
    }
}
  export function filter2(cam){
    if(document.getElementById('Camera_Model').value !== ''){
      var price = cam.filter(doc => doc.Model_Name === document.getElementById('Camera_Model').value)[0].Model_Price
      document.getElementById('Price').innerHTML = price;
      document.getElementById('Amount').innerHTML = price;
      document.getElementById('Quantity').value = 1
    }
  }
  export function filter3(vr){
    vr.forEach(doc => {if(doc.Model_Name===document.getElementById('VR_Name').value){
      document.getElementById('Price2').innerHTML = doc.Model_Price
      document.getElementById('Channel').innerHTML = doc.Channel
      document.getElementById('Audio_Channel').innerHTML = doc.Audio_Channel
      document.getElementById('Amount2').innerHTML = parseInt(doc.Model_Price)* parseInt(document.getElementById('Quantity2').value)
    }})
  }
  export function setQ(){
    if(document.getElementById('Quantity').value === '0'){
      document.getElementById('Quantity').value = 1
  }
  document.getElementById('Amount').innerHTML = document.getElementById('Price').innerHTML * document.getElementById('Quantity').value
  }
  export function setseries(setseries2,val){
    if(val==='IP'){
      setseries2('IP')
      document.getElementById('Camera_Resolution').innerHTML = '<option>1.3MP</option><option>2MP</option><option>4MP</option><option>6MP</option><option>8MP</option>'
    }else{
      setseries2('HD')
      document.getElementById('Camera_Resolution').innerHTML = '<option>1MP</option><option>2MP</option><option>4MP</option><option>5MP</option><option>6MP</option><option>8MP</option>'
    } 
  }
  export function filter4(type,arr,val){
    var obj = arr.filter(doc => doc.Model_Name === val)[0]
    document.getElementById('Price3').innerHTML  = obj.Model_Price
    if(type === 'PSU'){
      document.getElementById('Power_Rating').innerHTML = obj.Power_Rating
      document.getElementById('Company2').innerHTML = obj.Company
    }else{
      document.getElementById('Company2').innerHTML = obj.Company
      document.getElementById('POE_Size').innerHTML = obj.POE_Size
      document.getElementById('POE_Type').innerHTML = obj.POE_Type
      document.getElementById('Link').innerHTML = (obj.Link?'<i class="fa-solid fa-check"></i>':'<i class="bi bi-x-lg"></i>')
      document.getElementById('SFP').innerHTML = (obj.SFP?'<i class="fa-solid fa-check"></i>':'<i class="bi bi-x-lg"></i>')
    }
    document.getElementById('Amount3').innerHTML = parseInt(obj.Model_Price)*parseInt(document.getElementById('Quantity3').value)
  }
  export function filter5(type,val,arr){
    if(type==="HDD"){
      var obj = arr.filter(doc => doc.Model_Name === val)[0]
      document.getElementById('Terabyte').innerHTML = obj.Terabyte
      document.getElementById('Price4').innerHTML = obj.Model_Price
      document.getElementById('Company3').innerHTML = obj.Company
      document.getElementById('Amount4').innerHTML = parseInt(obj.Model_Price)*parseInt(document.getElementById('Quantity4').value)
    }
    if(type==="Monitor"){
      var obj2 = arr.filter(doc => doc.Model_Name === val)[0]
      document.getElementById('Price5').innerHTML = obj2.Model_Price
      document.getElementById('Company4').innerHTML = obj2.Company
      document.getElementById('Supported_Cables').innerHTML = obj2.Supported_Cable
      document.getElementById('Screen_Size').innerHTML = obj2.Monitor_Size
      document.getElementById('Amount5').innerHTML = parseInt(obj2.Model_Price)*parseInt(document.getElementById('Quantity5').value)
    }
  }
  export function filter6(val,arr){
    var obj3 = arr.filter(doc => doc.Model_Name === val)[0]
    document.getElementById('Price6').innerHTML = obj3.Model_Price
    document.getElementById('Company5').innerHTML = obj3.Company
    document.getElementById('Cable_Lenght').textContent = obj3.Cable_Lenght
    document.getElementById('Amount6').innerHTML = parseInt(obj3.Model_Price)*parseInt(document.getElementById('Quantity6').value)
  }
 export  function filter7(){

  }