import React from 'react';
import { Link } from 'react-router-dom';

export default function Manage() {
    document.title = "CCTV Quote - Manage";
    var css = `
        .btn-secondary {
            margin-bottom: 10px;
        }
        .btn-success, .btn-danger {
            margin-left: 10px;
            margin-bottom: 10px;
        }
        .card {
            border: none;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        .card:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(0,0,0,0.2);
        }
        .card-header {
            background-color: rgba(0,123,255,0.7);
            color: white;
        }
        .card-body {
            background-color: #f8f9fa;
        }
    `;
    
    return (
        <>
            <style>
                {css}
            </style>
            <div style={{ width: "100%", paddingRight: "5%", paddingTop: "5%" }}>
                <div className="row">
                    <div className="col-xs-12 col-sm-3 mb-4">
                        <div className="card">
                            <div className="card-header fw-bold text-center">Price Update</div>
                            <div className="card-body text-center">
                                <Link to="./Manage/Price_Update" className="btn btn-secondary">Update</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-3 mb-4">
                        <div className="card">
                            <div className="card-header fw-bold text-center">Hide Product</div>
                            <div className="card-body text-center">
                                <Link to="./Manage/Hide_Product" className="btn btn-secondary">Hide</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-3 mb-4">
                        <div className="card">
                            <div className="card-header fw-bold text-center">Unhide Product</div>
                            <div className="card-body text-center">
                                <Link to="./Manage/Unhide_Product" className="btn btn-secondary">Unhide</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
