export default function addp(val,arr,func) {
    var tbody = document.querySelector("."+val+"-tbody")
    if(val === 'PSU'){
    var row = document.createElement("tr")
    var td1 = document.createElement("td")
    var td2 = document.createElement("td")
    var td3 = document.createElement("td")
    var td4 = document.createElement("td")
    var td5 = document.createElement("td")
    var td6 = document.createElement("td")
    td1.innerHTML = document.getElementById("Model_Name3").value
    td2.innerHTML = document.getElementById("Price3").innerHTML
    td3.innerHTML = document.getElementById("Company2").innerHTML
    td4.innerHTML = document.getElementById("Power_Rating").innerHTML
    td5.innerHTML = document.getElementById("Quantity3").value
    td6.innerHTML = document.getElementById("Amount3").innerHTML
    row.append(td1,td2,td3,td4,td5,td6)
    arr = [{Model_Name: td1.innerHTML,
        Price:td2.innerHTML,
        Company:td3.innerHTML,
        Power_Rating:td4.innerHTML,
        Quantity:parseInt(td5.innerHTML),
        Amount:parseInt(td6.innerHTML)}]
    document.getElementById('p-btn').style.display = 'none'
    func(arr)
    tbody.append(row)
    document.getElementById("Model_Name3").disabled = true
    document.getElementById("Quantity3").disabled = true
    document.getElementById("Price3").disabled = true
    document.getElementById("Power_Rating").disabled = true
    document.getElementById("Amount3").disabled = true
    }else{
        var row2 = document.createElement("tr")
    var td12 = document.createElement("td")
    var td22 = document.createElement("td")
    var td32 = document.createElement("td")
    var td42 = document.createElement("td")
    var td52 = document.createElement("td")
    var td62 = document.createElement("td")
    var td7 = document.createElement("td")
    var td8 = document.createElement('td')
    var td9 = document.createElement('td')
    td12.innerHTML = document.getElementById("Model_Name3").value
    td22.innerHTML = document.getElementById("Price3").innerHTML
    td32.innerHTML = document.getElementById("Company2").innerHTML
    td42.innerHTML = document.getElementById("POE_Size").innerHTML
    td52.innerHTML = document.getElementById("POE_Type").innerHTML
    td62.innerHTML = document.getElementById("Link").innerHTML
    td7.innerHTML = document.getElementById("SFP").innerHTML
    td8.innerHTML = document.getElementById("Quantity3").value
    td9.innerHTML = document.getElementById("Amount3").innerHTML
    row2.append(td12,td22,td32,td42,td52,td62,td7,td8,td9)
    arr = [{Model_Name: td12.innerHTML,
        Price: td22.innerHTML,
        Company: td32.innerHTML,
        POE_Size: td42.innerHTML,
        POE_Type: td52.innerHTML,
        Link: (td62.innerHTML==='<i classname="fa-solid fa-check"></i>'?true:false),
        SFP: (td7.innerHTML==='<i classname="fa-solid fa-check"></i>'?true:false),
        Quantity:parseInt(td8.innerHTML),
        Amount:parseInt(td9.innerHTML)}]
    document.getElementById('p-btn').style.display = 'none'
    func(arr)
    tbody.append(row2)
    document.getElementById("Model_Name3").disabled = true
    document.getElementById("Quantity3").disabled = true
    document.getElementById("Price3").disabled = true
    document.getElementById("Amount3").disabled = true
    }
}