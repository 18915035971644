import { useAuth } from 'Backend/Authentication/Context'
import { firestore } from 'Backend/config'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
export default function Profile() {
  function handlesubmit(){
    updateDoc(doc(firestore, `Users/${currentUser.user.uid}`), {
      Business_Name: document.getElementById('businessname').value,
      Address_1: document.getElementById('address1').value,
      Address_2: document.getElementById('address2').value,
      Description: document.getElementById('description').value,
      Contact_No_2: document.getElementById('contactno2').value,
      GSTIN: document.getElementById('gstin').value
    })
  }
  const {currentUser} = useAuth()
  var [gstin, setGstin] = useState("")
  var [businessName, setBusinessName] = useState("")
  var [address1, setAddress1] = useState("")
  var [address2, setAddress2] = useState("")
  var [Description, setDescription] = useState("")
  var [contactNo, setContactNo] = useState("")
  useEffect(() => {
    document.querySelector(".update-btn").disabled = true
    currentUser.getdata((data)=>{
      if(data){
        setGstin(data.GSTIN)
        setBusinessName(data.Business_Name)
        setAddress1(data.Address_1)
        setDescription(data.Description)
        setContactNo(data.Contact_No_2)
        setAddress2(data.Address_2)
      }
    })
  },[])
  return (
    <div className="container">
      <br />
  <div className="row">
    <div className="col-12">
      <form>
            <div className="bg-secondary-soft px-4 rounded">
              <div className="row g-3">
                <div className="col-md-6">
                  <label className="form-label">GSTIN </label>
                  <input
                    type="text"
                    className="form-control"
                    id='gstin'
                    aria-label="GSTIN"
                    placeholder="GSTIN"
                    defaultValue={gstin}
                    onInput={(e)=>{if(e.target.value !== gstin){document.querySelector(".update-btn").disabled = false;}else{document.querySelector(".update-btn").disabled = true;}}}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">One Line Description </label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="One Line Description"
                    id='description'
                    placeholder="One Line Description"
                    defaultValue={Description}
                    onInput={(e)=>{if(e.target.value !== Description){document.querySelector(".update-btn").disabled = false;}else{document.querySelector(".update-btn").disabled = true;}}}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Secondary Phone number </label>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Secondary Phone number"
                    id='contactno2'
                    placeholder="Secondary Phone number"
                    defaultValue={contactNo}
                    onInput={(e)=>{if(e.target.value !== contactNo){document.querySelector(".update-btn").disabled = false;}else{document.querySelector(".update-btn").disabled = true;}}}
                  />
                </div>
                <div className="col-md-6">
                <label className="form-label">Business Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id='businessname'
                    aria-label="Business Name"
                    defaultValue={businessName}
                    onInput={(e)=>{if(e.target.value !== businessName){document.querySelector(".update-btn").disabled = false;}else{document.querySelector(".update-btn").disabled = true;}}}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">
                    Address Line 1 
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="Address Line 1"
                    id='address1'
                    defaultValue={address1}
                    onInput={(e)=>{if(e.target.value !== address1){document.querySelector(".update-btn").disabled = false;}else{document.querySelector(".update-btn").disabled = true;}}}
                  />
                </div>
                <div className="col-md-6">
                <label className="form-label">
                    Address Line 2 
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    aria-label="Address Line 2"
                    id='address2'
                    className="form-control"
                    defaultValue={address2}
                    onInput={(e)=>{if(e.target.value !== address2){document.querySelector(".update-btn").disabled = false;}else{document.querySelector(".update-btn").disabled = true;}}}
                  />
                </div>
              </div>
            </div>
          {/* <div className="col-xxl-6">
            <div className="bg-secondary-soft px-4 py-5 rounded">
              <div className="row g-3">
                <h4 className="my-4">Change Password</h4>
                <div className="col-md-6">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Old password *
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="exampleInputPassword2" className="form-label">
                    New password *
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword2"
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="exampleInputPassword3" className="form-label">
                    Confirm Password *
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword3"
                  />
                </div>
              </div>
            </div>
          </div> */}
          <br />
        <div className="gap-3 d-md-flex justify-content-md-end text-center">
          <button type='button' className="btn btn-primary update-btn" onClick={()=>{handlesubmit()}}>
            Update profile
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

  )
}
