import { getDocs,collection,query,where } from 'firebase/firestore'
import { firestore } from 'Backend/config'
import errormessage from 'components/Main/error'
function get(setcam){
  const querySnapshot = getDocs(query(collection(firestore, `Products/Camera/Camera`),where('Camera_Series','==',document.getElementById('Camera_Series').value),where('Company','==',document.getElementById('Company').value)))
  querySnapshot.then(snapshot=>{
    var set = []
    snapshot.docs.forEach((doc) => {
      var data = doc.data();data.id = doc.id;set.push(data)})
    setcam(set.map(doc => doc))
  })
}
export default function set(setcam,func){
    if(document.getElementById('Date').value !== ''){
      if(document.getElementById('Email').value !== ''){
        if(document.getElementById('Name').value !== ''){
          if(document.getElementById('Phone').value !== ''){
            if(document.getElementById('Address').value !== ''){
              get(setcam)
              document.querySelector('.camera-table-cont').style.display = 'block';
              document.getElementById('set').disabled = true
              document.querySelector('.basic-field').querySelectorAll('input').forEach((element)=>{
                element.disabled = true
              })
              document.querySelector('.basic-field').querySelectorAll('select').forEach((element)=>{
                element.disabled = true
              })
              func()
            }else{
              errormessage("Please Enter Address")
            }
          }else{
            errormessage("Please Enter Phone")
          }
        }else{
          errormessage("Please Enter Name")
        }
      }else{
        errormessage("Please Enter Email")
      }
            
    }else{
      errormessage("Please Select Date")
    }
  }