import React, { useContext, useState, useEffect } from "react";
import { auth } from "Backend/config";
import {  firestore } from 'Backend/config'
import {  doc, getDoc } from 'firebase/firestore'
import { onAuthStateChanged, RecaptchaVerifier, sendEmailVerification, signInWithPhoneNumber } from "firebase/auth";
import Swal from "sweetalert2";
import errormessage from "components/Main/error";
const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);
  async function initializeUser(user) {    
      if(user){
      if (user.uid) {    
        setCurrentUser({ user ,getdata: async function(func) {
          const data = await getDoc(doc(firestore,`Users/${user.uid}`));
          func(data.data())
        },sendEmailVerification: async function() {
          return await sendEmailVerification(auth.currentUser)
        }
       });
      setUserLoggedIn(true);
    }
    }else{
      setUserLoggedIn(false);
    }
    setLoading(false);
  }
  function verifyemail(user) {
    return sendEmailVerification(user)
  }
  function setUpRecaptha(number,func) {
    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      "callback": (response) => {console.log(response);}
    });
      signInWithPhoneNumber(auth, "+91 " + number, recaptchaVerifier).then((response) => {
        Swal.fire({
          title: 'Enter OTP',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Verify',
          showLoaderOnConfirm: true,
          preConfirm: async (otp) => {
            try {
              const result = await response.confirm(otp)
              if (result) {
                return result.user
              }
            } catch (err) {
              Swal.showValidationMessage(`OTP is incorrect`)
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
          if (result.isConfirmed) {
            const user = result.value
            func(user)
          }
        })
      }).catch((error) => {
        errormessage(error.message)
      });
  }
  const value = {
    userLoggedIn,
    currentUser,
    setUpRecaptha,
    verifyemail,
    setCurrentUser
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
