import addproduct from 'Backend/Firestore/upload'
import validateproduct from 'Backend/Firestore/validate'
import upload2 from 'Backend/Storage/upload2'
import errormessage from 'components/Main/error'
import React from 'react'
import Swal from 'sweetalert2'

export default function Cable() {
    const css = `
    .form-control{
          width: 99%;
      }
      body{
          overflow-x: hidden;
      }
      input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    `
    function Back() {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure that you want to go back?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, go back!'
        }).then((result) => {
          if (result.isConfirmed) {
            history.back()
          }
        })
    }
      function Add(){
        if(document.getElementById("Model_Name").value === "" ){
          errormessage("Please Enter Model Name")
        }else if(document.getElementById("Model_Price").value === "" ){
          errormessage("Please Enter Model Price")
        }else if(document.getElementById("Cable_Picture").value === "" ){
          errormessage("Please Upload Picture")
        }else{
          validateproduct('Cable',document.getElementById("Model_Name").value,()=>{
            upload2("Cable",document.getElementById("Model_Name").value,document.getElementById("Cable_Picture").files[0],(url)=>{
              addproduct(document.getElementById('Available').value,"Cable",{
                Model_Name: document.getElementById("Model_Name").value,
                Model_Price: document.getElementById("Model_Price").value,
                Company: document.getElementById("Company").value,
                Cable_Series: document.getElementById("Camera_Series").value,
                Cable_Lenght: document.getElementById("Cable_Lenght").value,
                Image:url
              })
            })
          })
        }
      }
  return (
    <>
    <style>{css}</style>
      <center><h1>Add Cable</h1></center>
      <div className="row" style={{width:'80%',marginLeft: '10%'}}>
        <div className="col-sm-12">
        <label htmlFor="Model_Name" className="form-label" style={{fontWeight: "bold"}}>Model Name</label>
                <input type="text" id="Model_Name" data-name="Model_Name" required className="form-control"/>
                <label htmlFor="Model_Price" className="form-label" style={{fontWeight: "bold"}}>Model Price</label>
                <input type="number" id="Model_Price" data-name="Model_Price" required className="form-control"/>
                <label htmlFor="Company" className="form-label" style={{fontWeight: "bold"}}>Company</label>
                <select data-name="Company" id="Company" className="form-control">
                    <option defaultValue="HIKVISION">HIKVISION</option>
                    <option defaultValue="CPPLUS">CPPLUS</option>
                    <option defaultValue="PRAMA">PRAMA</option>
                </select>
                <label htmlFor="Cable_Picture" className="form-label" style={{fontWeight: "bold"}}>Cable Picture</label>
                <input type="file" id="Cable_Picture" data-name="Cable_Picture" accept="image/*" className="form-control"/>
                <label htmlFor="Camera_Series" className="form-label" style={{fontWeight: "bold"}}>Camera Series</label>
                <select defaultValue={"IP"} data-name="Camera_Series" id="Camera_Series" className="form-control" onChange={(e) => e.target.value==='IP'?document.getElementById('Cable_Lenght').innerHTML = '<option>100M</option><option>305M</option>':document.getElementById('Cable_Lenght').innerHTML = '<option>90M</option><option>180M</option>'}>
                    <option defaultValue="HD">HD</option>
                    <option defaultValue="IP">IP</option>
                    </select>
                    <label htmlFor="Cable_Lenght" className="form-label" style={{fontWeight: "bold"}}>Cable Lenght</label>
                    <select data-name="Cable_Lenght" id="Cable_Lenght" className="form-control">
                        <option defaultValue="100M" defaultChecked>100M</option>
                        <option defaultValue="305M">305M</option>
                    </select>
                    <label htmlFor="Available" className="form-label" style={{fontWeight: "bold"}}>Available</label>
                    <select id="Available" className="form-control">
                        <option defaultValue="Yes">Yes</option>
                        <option defaultValue="No">No</option>
                    </select>
                    <br />
                <div className='row'>
                <div className='col-sm-12' style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                <button className="btn btn-primary" type="button" style={{marginRight: '10px'}} onClick={()=>{Add()}}>Save</button>
                <button className="btn btn-danger" type="button" onClick={()=>{Back()}}>Back to Products</button>
                </div>
                </div>
        </div>
      </div>
    </>
  )
}
